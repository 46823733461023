import React, { useEffect } from "react";
import { useGetCards, useUserPayments } from "../../../zustand/store";
import { PageContainer } from "../../../components/container";
import { Checkbox, Table } from "antd";
import { useParams } from "react-router-dom";

function Payments() {
  const payments = useUserPayments();
  const cards = useGetCards();

  const { id } = useParams();
  const userId = id;

  useEffect(() => {
    // payments.execute();
    cards.execute();
  }, []);
  return (
    <div>
      <Checkbox.Group
        onChange={(cards_id) => {
          console.log(cards_id);
          payments.execute({ params: { userId, cards_id }, force: true });
        }}
      >
        {cards?.data?.map(({ id, data }) => (
          <Checkbox value={id}>{data?.number}</Checkbox>
        ))}
      </Checkbox.Group>
      <Table dataSource={payments.data?.list || []} />
    </div>
  );
}

export default Payments;
