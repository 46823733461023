import { Input, Modal } from "antd";
import React, { useState } from "react";
import { LangText } from "../../langManagement";
import { useDeleteStation, useGetStation } from "../../../zustand/store";
import { useParams } from "react-router-dom";

function DeleteStationModal({ open, data, onCancel }) {
  const deleteStation = useDeleteStation();
  const [input, setInput] = useState("");
  const { cpoId, cpId } = useParams();
  const station = useGetStation();

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      title={<LangText id="delete" />}
      okText={<LangText id="delete" />}
      // okButtonProps={{ disabled: input !== data?.name }}
      onOk={() => {
        deleteStation.execute({
          params: { id: data?.id },
          onSuccess: () => {
            station.execute({ params: { cpId } });
            onCancel();
          },
        });
      }}
    >
      {/* {data?.name} */}
      <LangText id="reallyWantToDeleteThisStation" />

      {/* <Input
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
      /> */}
    </Modal>
  );
}

export default DeleteStationModal;
