import { Card } from "antd";
import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import { thousandSeparator } from "../../utils/numberManager";
import {
  useGetBalanceData,
  useGetMe,
  useGetMePerson,
  useGetProfile,
} from "../../zustand/store";
import PackagesPage from "../packagesPage";

function MePage() {
  const me = useGetMe();

  const getMe = useGetMePerson();

  const balanceData = useGetBalanceData();
  const profile = useGetProfile();
  // const payments = useUserPayments();
  // const charging = useUserCharging();

  useEffect(() => {
    me.execute();
    balanceData.execute();
    profile.execute();
    getMe.execute();
    // payments.execute();
    // charging.execute();
  }, []);
  return (
    <>
      <PageContainer title="Me">
        <Card size="small">
          <div>phoneNumber: {me.data?.phoneNumber}</div>
          <div>Balance: {thousandSeparator(me.data?.money)} so'm</div>
        </Card>
      </PageContainer>
      <PackagesPage />
    </>
  );
}

export default MePage;