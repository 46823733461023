const URL = {
  development: "http://localhost:6464",
  production: "https://bloom-api.kaktus365.uz",
};

const WS_URL = {
  development: "ws://localhost:7644",
  production: "wss://beon-ws.kaktus365.uz",
};

const NODE_ENV = process.env?.NODE_ENV;

export const BASE_URL =
  URL[NODE_ENV] ||
  URL.production;

export const WS_BASE_URL =
  // WS_URL[NODE_ENV] ||
  WS_URL.production;

