import { Button, Col, Drawer, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import UploadSingleImage from "../../../components/uploadImage/uploadSingleImage";
import { LangText } from "../../../components/langManagement";
import { useAddPlug, useEditPlug } from "../../../zustand/store";

function PlugForm({ open, onClose, data, edit }) {
  const [image, setImage] = useState({});
  const [icon, setIcon] = useState({});
  const [form] = Form.useForm();

  const addPlug = useAddPlug();
  const editPlug = useEditPlug();

  useEffect(() => {
    if (edit) {
      console.log(data);
      setImage({ id: data?.image });
      setIcon({ id: data?.icon });
      form.setFieldsValue({
        name: data?.name || "",
      });
    }
  }, [open]);
  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        form.resetFields();
        setIcon({});
        setImage({});
      }}
    >
      <Row gutter={[20, 20]}>
        <Col>
          <div
            style={{
              textAlign: "center",
              fontSize: "18px",
              marginBottom: "5px",
            }}
          >
            <LangText id="image" />
          </div>
          <UploadSingleImage file={image} setFile={setImage} />
        </Col>
        <Col>
          <div
            style={{
              textAlign: "center",
              fontSize: "18px",
              marginBottom: "5px",
            }}
          >
            <LangText id="icon" />
          </div>
          <UploadSingleImage file={icon} setFile={setIcon} />
        </Col>
      </Row>
      <br />
      <Form
        form={form}
        layout="vertical"
        onFinish={(e) => {
          console.log(e);
          (edit ? editPlug : addPlug).execute({
            params: { id: data?.id },
            data: { ...e, icon, image },
            onSuccess: () => {
              form.resetFields();
              setIcon({});
              setImage({});
              onClose();
            },
          });
        }}
      >
        <Form.Item
          label={<LangText id="name" />}
          required
          rules={[{ required: true, message: "Please enter plug name" }]}
          name={"name"}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={!((image.id || image.base64) && (icon.id || icon.base64))}
            htmlType="submit"
          >
            <LangText id={edit ? "edit" : "add"} />
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default PlugForm;
