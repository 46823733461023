import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import {
  useAddDiscount,
  useDiscountCPO,
  useDiscounts,
  useDiscountsUpcoming,
} from "../../zustand/store";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Row,
  Table,
} from "antd";
import { BASE_URL } from "../../constants/link";

const list = [
  {
    id: "9",
    price: 2500,
    from: "2024-03-30T13:42:13.951Z",
    to: "2024-04-30T13:42:13.951Z",
    stationList: ["51", "12", "13"],
    active: true,
    createdAt: "2024-03-10T13:42:31.498Z",
    updatedAt: "2024-03-10T21:02:26.642Z",
    cpoId: "10",
    charging_stations: [
      {
        id: "12",
        serial_number: "UZ00015",
        capacity_of_evc: 180,
        images: ["bc3457bd-6608-4d47-ac5d-08a553cf38ec"],
        idle_charger_fee: 1150,
        electricity_fee: 4800,
        ocpp_id: "00000001",
        active: true,
        stationId: null,
        sessionStartedAt: null,
        sessionId: null,
        password: null,
        status: "UNAVAILABLE",
        lastHeartbeatAt: "2024-03-14T11:28:06.918Z",
        stationBootData: {
          imsi: "",
          iccid: "",
          meterType: "ELM NQC-ACDC",
          firmwareVersion: "0.9.87",
          chargePointModel: "Elmo-Virtual1",
          chargePointVendor: "Elmo",
          meterSerialNumber: "elm.001.13.1.01",
          chargeBoxSerialNumber: "elm.001.13.1.01",
          chargePointSerialNumber: "elm.001.13.1",
        },
        idTag: "Jm8zHkeBqUyoaYi7JoM7",
        createdAt: "2024-02-17T16:03:24.946Z",
        updatedAt: "2024-03-14T11:29:06.976Z",
        chargingPointId: "11",
        discountId: "9",
        charging_point: {
          id: "11",
          name: "Nur 180Kw",
          address: "Angren Nurchi ko'chasi",
          addressText: null,
          geoLocation: {
            lat: 41.30811605045248,
            lng: 69.28133010864259,
          },
          parkingType: "OPEN",
          workHours: {
            to: "23:59",
            from: "0:0",
          },
          parkingFeeWhileCharging: 500,
          withUmbrella: true,
          verified: {
            by: "939ee1c0-a6e8-47ec-a45b-5cb5b347d546",
            date: 1710152792978,
          },
          active: true,
          createdAt: "2024-02-17T15:58:48.323Z",
          updatedAt: "2024-03-11T10:26:32.978Z",
          cpoId: "10",
        },
      },
      {
        id: "13",
        serial_number: "UZ00017",
        capacity_of_evc: 160,
        images: ["114e6d14-08b2-4fd7-8fc0-717347447359"],
        idle_charger_fee: 1150,
        electricity_fee: 4800,
        ocpp_id: "00000002",
        active: true,
        stationId: null,
        sessionStartedAt: null,
        sessionId: null,
        password: null,
        status: "UNAVAILABLE",
        lastHeartbeatAt: "2024-03-14T19:57:12.780Z",
        stationBootData: {
          imsi: "",
          iccid: "",
          meterType: "ELM NQC-ACDC",
          firmwareVersion: "0.9.87",
          chargePointModel: "Elmo-Virtual1",
          chargePointVendor: "Elmo",
          meterSerialNumber: "elm.001.13.1.01",
          chargeBoxSerialNumber: "elm.001.13.1.01",
          chargePointSerialNumber: "elm.001.13.1",
        },
        idTag: "rHwnzDqSCoryVWRYcTXa",
        createdAt: "2024-02-17T16:06:33.901Z",
        updatedAt: "2024-03-14T19:58:12.780Z",
        chargingPointId: "12",
        discountId: "9",
        charging_point: {
          id: "12",
          name: "Nur 160Kw",
          address: "Nurchi B-2 ko'chasi",
          addressText: null,
          geoLocation: {
            lat: 41.32629504031827,
            lng: 69.29231643676759,
          },
          parkingType: "OPEN",
          workHours: {
            to: "23:59",
            from: "0:0",
          },
          parkingFeeWhileCharging: 500,
          withUmbrella: true,
          verified: {
            by: "939ee1c0-a6e8-47ec-a45b-5cb5b347d546",
            date: 1710152782708,
          },
          active: true,
          createdAt: "2024-02-17T16:05:02.591Z",
          updatedAt: "2024-03-11T10:26:22.709Z",
          cpoId: "10",
        },
      },
      {
        id: "51",
        serial_number: "UZ00020",
        capacity_of_evc: 150,
        images: ["69b1eaca-e723-4a65-b418-b79864747515"],
        idle_charger_fee: 0,
        electricity_fee: 4800,
        ocpp_id: "000000001",
        active: true,
        stationId: null,
        sessionStartedAt: null,
        sessionId: null,
        password: null,
        status: null,
        lastHeartbeatAt: null,
        stationBootData: null,
        idTag: null,
        createdAt: "2024-03-01T11:54:17.432Z",
        updatedAt: "2024-03-14T20:14:16.234Z",
        chargingPointId: "44",
        discountId: "9",
        charging_point: {
          id: "44",
          name: "Nur 180Kw test 01",
          address: null,
          addressText: {
            en: "Labzak, Yunusabad district test 01",
            ru: "Labzak, Yunusabad district test 01",
            uz: "Labzak, Yunusabad district test 01",
          },
          geoLocation: {
            lat: 41.32745522922452,
            lng: 69.25025939941408,
          },
          parkingType: "CLOSED",
          workHours: {
            to: "23:59",
            from: "0:0",
          },
          parkingFeeWhileCharging: 0,
          withUmbrella: false,
          verified: {
            by: "939ee1c0-a6e8-47ec-a45b-5cb5b347d546",
            date: 1710150981735,
          },
          active: true,
          createdAt: "2024-03-01T11:52:59.607Z",
          updatedAt: "2024-03-11T09:56:21.735Z",
          cpoId: "10",
        },
      },
    ],
    cpo: {
      id: "10",
      name: "Nur M.CH.J",
      tradeMark: "Nurchi",
      logo: "191c20c4-97ca-4fe5-9a4a-b0f8dbf167e6",
      location: {
        lat: 41.3279023,
        lng: 69.2680871,
        addressText: {
          en: "Angren",
          ru: "Ангрен",
          uz: "Angren",
        },
      },
      color: "#2d907b",
      contact_beon: {
        email: "nurchi@gmail.com",
        website: "https://nurchi.uz",
        phoneNumber: "+998909927188",
      },
      contact: {
        email: "nurchi@gmail.com",
        website: "https://nurchi.uz",
        phoneNumber: "+998909927188",
      },
      verified: {
        by: "939ee1c0-a6e8-47ec-a45b-5cb5b347d546",
        date: 1710015331545,
      },
      partnership: {
        app: {},
        status: "DIRECT",
      },
      active: true,
      rate: 0,
      rateCount: 0,
      projectOwner: false,
      createdAt: "2024-02-17T15:53:02.828Z",
      updatedAt: "2024-03-09T20:55:54.696Z",
    },
  },
];

function DiscountForm() {
  const addDiscount = useAddDiscount();
  return (
    <Form
      onFinish={(e) => {
        let { price, from, to } = e;
        price = Number(price);
        const stationList = ["54"];
        addDiscount.execute({ data: { price, from, to, stationList } });

        console.log({ price, from: from.format(), to: to.format() });
      }}
    >
      <Form.Item name={"price"} label="price">
        <Input />
      </Form.Item>
      <Form.Item name="from" label="From">
        <DatePicker />
      </Form.Item>
      <Form.Item name="to" label="To">
        <DatePicker />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
}

function DiscountPage() {
  const discounts = useDiscounts();
  const discountsUpcoming = useDiscountsUpcoming();
  const discountCPO = useDiscountCPO();

  useEffect(() => {
    discountCPO.execute();
    discounts.execute();
    discountsUpcoming.execute();
  }, []);

  return (
    <PageContainer title={"Discount"}>
      DiscountPage
      <Table
        columns={["id", "discount"].map((c) => ({
          key: c,
          dataIndex: c,
          title: c,
        }))}
        dataSource={discountCPO.data || []}
      />
      <DiscountForm />
      <Row gutter={[15, 15]}>
        {discountsUpcoming?.data?.list?.map((discount) => {
          const cpo = discount?.cpo;
          return (
            <>
              {discount?.charging_stations?.map((station) => {
                const cp = station.charging_point;
                return (
                  <Col span={24}>
                    <Card
                      size="small"
                      title={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            style={{ width: "50px" }}
                            src={`${BASE_URL}/file/${cpo?.logo}`}
                            alt=""
                          />
                          <div>
                            <div>{cpo?.name}</div>
                            <div style={{ fontWeight: "lighter" }}>
                              {cp?.address}
                            </div>
                          </div>
                        </div>
                      }
                    >
                      {/* /<Card size="small"> */}
                      <div style={{ marginBottom: "10px" }}>
                        {station.serial_number}
                      </div>
                      {/* </Card> */}

                      <List bordered size="small">
                        {station.connectors?.map((c) => (
                          <List.Item>
                            <div>
                              <div>{c?.plug?.name}</div>
                              <div>{c?.capacity} кВт</div>
                            </div>
                          </List.Item>
                        ))}
                      </List>
                      <div style={{ marginTop: "10px" }}>
                        <Button block type="primary">
                          {discount.price} UZS
                        </Button>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </>
          );
        })}
      </Row>
    </PageContainer>
  );
}

export default DiscountPage;
