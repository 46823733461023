import { Table } from "antd";
import React, { useEffect } from "react";
import { useUserCharging } from "../../../zustand/store";

function Charging() {
  const charging = useUserCharging();

  useEffect(() => {
    // charging.execute();
  }, []);

  return (
    <div>
      {/* Charging */}
      <Table dataSource={charging.data?.list || []} />
    </div>
  );
}

export default Charging;
