import { BrowserRouter } from "react-router-dom";
import "./App.css";
import MainPage from "./pages/mainPage";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          // colorPrimary: "#07BC84",
          // borderRadius: 2,
          colorBorderSecondary: "black",
          // colorBorder: "black",
          // colorBorderBg: "black",

          // Alias Token
          // colorBgContainer: "#f6ffed",
        },
      }}
      // locale={ln[lang.toLowerCase()] || ru}
    >
      <BrowserRouter>
        <MainPage />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
