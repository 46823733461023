import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Radio,
  Row,
  TimePicker,
} from "antd";
import MapBox from "../../components/mapBox";
import {
  useAddChargingPoint,
  useEditChargingPoint,
  useGetNearby,
  useGetSingleCP,
} from "../../zustand/store";
import { BASE_URL } from "../../constants/link";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import NearbyLocationForm from "../../components/modal/nearbyLocationForm";
import { displayAddress } from "../../utils/address.helper";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

function FormChargingPoint() {
  const [markerCoords, setMarkerCoords] = useState({});
  const nearby = useGetNearby();
  const singleCP = useGetSingleCP();
  const addChargingPoint = useAddChargingPoint();
  const editChargingPoint = useEditChargingPoint();

  const { cpoId, cpId } = useParams();
  const edit = Boolean(cpId);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [nearbyLocationList, setNearbyLocationList] = useState([]);
  const [nearbyLocationModal, setNearbyLocationModal] = useState({
    open: false,
  });

  useEffect(() => {
    nearby.execute();
    if (cpId) {
      singleCP.execute({
        params: { id: cpId },
        force:true,
        onSuccess: (res) => {
          const data = res.data;

          form.setFieldsValue({
            ...data,
            from: dayjs(data.workHours.from, "HH:mm"),
            to: dayjs(data.workHours.to, "HH:mm"),
          });
          setMarkerCoords(data?.geoLocation);
          setNearbyLocationList(data.nearby_locations);
        },
      });
    }
  }, [cpId]);
  return (
    <PageContainer title={<LangText id="createCharingPoint" />}>
      <Form
        form={form}
        layout="vertical"
        onFinish={(e) => {
          const reqData = {
            ...e,
            from: undefined,
            to: undefined,
            geoLocation: markerCoords,
            cpo_id: cpoId,
            workHours: {
              from: `${e.from.hour()}:${e.from.minute()}`,
              to: `${e.to.hour()}:${e.to.minute()}`,
            },
            nearby: nearbyLocationList,
          };
          (edit ? editChargingPoint : addChargingPoint).execute({
            params: { id: cpId },
            data: reqData,
            onSuccess: () => {
              navigate(`/cpo/${cpoId}`);
            },
          });
        }}
      >
        <Form.Item
          name={"address"}
          label={<LangText id="address" />}
          required
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <MapBox
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
          />
        </Form.Item>
        <Form.Item label={<LangText id="nearbyPlaces" />}>
          <Row>
            {nearby.data?.map(({ name, image, id }) => (
              <Col>
                <Card
                  style={{ width: "fit-content" }}
                  size="small"
                  key={id}
                  actions={[
                    <PlusOutlined
                      onClick={() => {
                        setNearbyLocationModal({
                          open: true,
                          nearby: { name, image, id },
                        });
                      }}
                    />,
                  ]}
                >
                  <Card.Meta
                    avatar={<Avatar src={`${BASE_URL}/file/${image}`} />}
                    title={name}
                    // description="This is the description"
                  />
                  {/* <img
                style={{ width: "70px" }}
                src={`${BASE_URL}/file/${image}`}
                alt="nearby icon"
              />
              <div style={{ textAlign: "center" }}>{name}</div> */}
                </Card>
              </Col>
            ))}
          </Row>
        </Form.Item>
        <Form.Item>
          <Row gutter={[10, 10]}>
            {nearbyLocationList?.map(({ name, geoLocation, nearby }, index) => (
              <Col>
                <Card size="small" style={{ width: "fit-content" }}>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Card.Meta
                      avatar={
                        <Avatar src={`${BASE_URL}/file/${nearby.image}`} />
                      }
                      title={nearby?.name}
                      description={displayAddress({
                        address: name,
                        ...geoLocation,
                      })}
                    />
                    <CloseCircleOutlined
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={() => {
                        setNearbyLocationList((prev) =>
                          prev.filter((v, i) => i !== index)
                        );
                      }}
                    />
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Form.Item>
        <Row gutter={[10, 10]}>
          <Col>
            <Form.Item
              label={<LangText id="from" />}
              name={"from"}
              required
              rules={[{ required: true }]}
            >
              <TimePicker
                format={"HH:mm"}
                // onChange={(from) => {
                //   const to = form.getFieldValue("to");
                //   const fromHour = from?.hour();
                //   const fromMinute = from?.minute();
                //   const toHour = to?.hour();
                //   const toMinute = to?.minute();
                //   if (
                //     fromHour === 0 &&
                //     fromMinute === 0 &&
                //     toHour === 23 &&
                //     toMinute === 59
                //   ) {
                //   console.log(fromHour, fromMinute, toHour, toMinute);

                //     form.setFieldValue("wholeDay", true);
                //   }
                // }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={<LangText id="to" />}
              name={"to"}
              required
              rules={[{ required: true }]}
            >
              <TimePicker
                format={"HH:mm"}
                // onChange={(e) => {
                //   console.log(e);
                // }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={<LangText id=" " />} name={"wholeDay"}>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    form.setFieldsValue({
                      from: dayjs().hour(0).minute(0).second(0),
                      to: dayjs().hour(23).minute(59).second(59),
                    });
                  }
                }}
                // value="wholeDay"
              >
                24/7
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[70, 10]}>
          <Col>
            <Form.Item
              label={<LangText id="parkingType" />}
              required
              rules={[{ required: true }]}
              name={"parkingType"}
            >
              <Radio.Group>
                <Row gutter={30}>
                  <Col>
                    <Radio value={"OPEN"}>
                      <LangText id="open" />
                    </Radio>
                  </Col>
                  <Col>
                    <Radio value={"CLOSED"}>
                      <LangText id="closed" />
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={<LangText id="hasUmbrella" />}
              required
              rules={[{ required: true }]}
              name={"withUmbrella"}
            >
              <Radio.Group>
                <Row gutter={30}>
                  <Col>
                    <Radio value={true}>
                      <LangText id="yes" />
                    </Radio>
                  </Col>
                  <Col>
                    <Radio value={false}>
                      <LangText id="no" />
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={<LangText id="parkingPrice" />}
              required
              rules={[{ required: true }]}
              name={"parkingFeeWhileCharging"}
            >
              <Input addonAfter={<LangText id={"soum/min"} />} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={" "}>
          <Row gutter={10}>
            <Col>
              <Button
                onClick={() => {
                  navigate(`/cpo/${cpoId}`);
                }}
              >
                <LangText id={"cancel"} />
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                <LangText id={edit ? "saveChanges" : "create"} />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <NearbyLocationForm
        {...nearbyLocationModal}
        onSuccess={(v) => {
          setNearbyLocationList((prev) => [...prev, v]);
          setNearbyLocationModal({ open: false });
        }}
        onCancel={() => {
          setNearbyLocationModal({ open: false });
        }}
      />
    </PageContainer>
  );
}

export default FormChargingPoint;
