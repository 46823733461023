import L from "leaflet"; // Import Leaflet library
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import { useGetMe } from "../../zustand/store";
import "./style.css";
import { Wrapper } from "./styles";

// URL to the custom marker icon image
const markerIconUrl =
  "https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png";

// Create a custom icon using the image URL
// const customIcon = L.icon({
const customIcon = (name) =>
  L.divIcon({
    // iconUrl: markerIconUrl,
    className: "custom-icon",
    html: `<img style="width:100%;height:100%" src="https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png"/>${
      name ? `<div class="box">${name}</div>` : ""
    }`,
    iconSize: [25, 41], // size of the icon
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  });

const redIcon = new L.Icon({
  iconUrl:
    "https://api.geoapify.com/v1/icon/?type=material&color=red&size=large&icon=home&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function MyComponent({
  currentCoords,
  markerCoords,
  setMarkerCoords,
  setCurrentCoords,
}) {
  const me = useGetMe();
  const map = useMapEvents({
    click: (res) => {
      map.locate();
      setCurrentCoords(res?.latlng);
      setMarkerCoords(res?.latlng);
    },
  });
  useEffect(() => {
    if (markerCoords && Object.keys(markerCoords).length) {
      map?.setView(markerCoords, map?.getZoom(), { animate: true });
    }
    const restaurant = me?.data?.restaurant;
    if (restaurant?.coords && !markerCoords) {
      setMarkerCoords(restaurant?.coords);
    }
  }, []);
  return null;
}

function MapBox({ markerCoords, setMarkerCoords, name }) {
  const [currentCoords, setCurrentCoords] = useState({
    lat: 33.7111401,
    lng: -78.8920569,
  });
  const me = useGetMe();
  const restaurantCoords = me?.data?.restaurant?.coords;

  useEffect(() => {
    markerCoords && setCurrentCoords(markerCoords);
  }, []);

  return (
    <Wrapper>
      <div style={{ width: "100%", height: "300px" }}>
        <MapContainer
          style={{ width: "100%", height: "100%" }}
          center={currentCoords}
          zoom={13}
          scrollWheelZoom
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {restaurantCoords ? (
            <Marker position={restaurantCoords} icon={redIcon} />
          ) : (
            <></>
          )}
          {Object.keys(markerCoords)?.length ? (
            <Marker
              draggable
              position={markerCoords}
              icon={customIcon(name)}
              eventHandlers={{
                dragend: (res) => {
                  setMarkerCoords(res.target.getLatLng());
                },
              }}
            />
          ) : (
            <></>
          )}

          <MyComponent
            currentCoords={currentCoords}
            setCurrentCoords={setCurrentCoords}
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
          />
        </MapContainer>
      </div>
    </Wrapper>
  );
}

export default MapBox;
