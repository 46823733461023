import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import { useGetNearby } from "../../zustand/store";
import NearbyForm from "./nearbyForm";
import SingleNearby from "./singleNearby";

function NearbyPage() {
  const [form, setForm] = useState({ open: false });
  const nearby = useGetNearby();

  useEffect(() => {
    nearby.execute();
  }, []);

  return (
    <PageContainer
      title={<LangText id="nearby" />}
      rightPart={
        <>
          <Button type="primary" onClick={() => setForm({ open: true })}>
            <PlusOutlined /> <LangText id="createNearby" />
          </Button>
        </>
      }
    >
      <Row gutter={[10, 10]}>
        {nearby.data?.map((v) => (
          <Col span={6}>
            <SingleNearby {...v} setForm={setForm} />
          </Col>
        ))}
      </Row>

      <NearbyForm
        edit={form?.edit}
        data={form?.data}
        open={form.open}
        onClose={() => setForm({ open: false })}
      />
    </PageContainer>
  );
}

export default NearbyPage;
