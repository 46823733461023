import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import {
  useGetCpoTransactions,
  useGetSingleTransaction,
  useGetTransactions,
  useTransactionsCPO,
} from "../../zustand/store";

function TransactionsPage() {
  const cpoList = useTransactionsCPO();
  const transactions = useGetTransactions();
  const cpoTransactions = useGetCpoTransactions();
  const single = useGetSingleTransaction();
  useEffect(() => {
    cpoList.execute();
    cpoTransactions.execute({
      // params: { text: "00020" },
    });
    transactions.execute({
      // params: { from: new Date().toISOString(), to: new Date().toISOString() },
    });
    single.execute();
  }, []);
  return (
    <PageContainer title={"TransactionsPage"}>
      TransactionsPage
      {/* {cpo} */}
    </PageContainer>
  );
}

export default TransactionsPage;
