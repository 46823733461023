import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import {
  useConnectorBelongings,
  useConnectorById,
  useGetCP_ById,
  useGetSingleCP,
  useStartCharging,
  useStopCharging,
} from "../../zustand/store";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Image, List, Row, Typography } from "antd";
import { BASE_URL } from "../../constants/link";
import WS from "../../utils/ws";

function SingleCPPage() {
  const singleCP = useGetCP_ById();
  const startCharging = useStartCharging();
  const stopCharging = useStopCharging();
  const charging_stations = singleCP.data?.charging_stations;
  const { id } = useParams();
  const belongings = useConnectorBelongings();
  const connectorById = useConnectorById();

  useEffect(() => {
    singleCP.execute({ params: { id }, force: true });
  }, []);

  useEffect(() => {
    if (charging_stations?.length) {
      const id = charging_stations?.[0]?.connectors?.[0]?.id;
      belongings.execute({ params: { id } });
      connectorById.execute({ params: { id } });
    }
  }, [charging_stations]);

  const packages = belongings.data?.packages;
  const coupons = belongings.data?.coupons;

  const packageId = packages?.map(({ id }) => id) || [];
  const couponId = (coupons?.map(({ id }) => id) || [])?.[0];

  return (
    <PageContainer
      title={
        <div>
          {singleCP.data?.name || "Single CP"} #
          <Typography.Text copyable>{singleCP.data?.id}</Typography.Text>
        </div>
      }
    >
      SingleCPPage
      <Row gutter={[10, 10]}>
        {charging_stations?.map((station) => (
          <>
            <Col>
              <Card
                size="small"
                title={
                  <Row justify={"space-between"}>
                    <Col>
                      #<Typography.Text copyable>{station?.id}</Typography.Text>
                    </Col>
                    <Col>{station?.serial_number}</Col>
                    <Col>{station?.status}</Col>
                  </Row>
                }
              >
                <List bordered size="small">
                  {station?.connectors?.map((connector) => (
                    <>
                      <List.Item>
                        <Row gutter={[10, 10]}>
                          <Col>
                            <Image
                              width={50}
                              src={`${BASE_URL}/file/${connector?.plug?.image}`}
                            />
                          </Col>
                          <Col>
                            <div>
                              <div>
                                #
                                <Typography.Text copyable strong>
                                  {connector?.id}
                                </Typography.Text>
                              </div>
                              <div>
                                {connector?.plug?.name} $
                                {connector?.price?.mainPrice}
                              </div>
                            </div>
                            <div>{connector?.status}</div>
                          </Col>
                          <Col>
                            <Button
                              onClick={() => {
                                startCharging.execute({
                                  data: {
                                    id: connector?.id,
                                    packageId,
                                    // couponId,
                                  },
                                });
                              }}
                            >
                              Start
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              onClick={() => {
                                stopCharging.execute({
                                  data: {
                                    id: connector?.id,
                                  },
                                });
                              }}
                            >
                              Stop
                            </Button>
                          </Col>
                        </Row>
                      </List.Item>
                      <WS connector={connector?.id} cpId={id} />
                    </>
                  ))}
                </List>
              </Card>
            </Col>
            <WS station={station?.id} cpId={id} />
          </>
        ))}
      </Row>
    </PageContainer>
  );
}

export default SingleCPPage;
