import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import { Button, Card, Col, Row } from "antd";
import {
  useGetNotifications,
  useMyNotifications,
  useSendNotification,
} from "../../zustand/store";

function NotificationPage() {
  const data = {
    title: {
      uz: "Xush kelibsiz",
      ru: "Privet",
      en: "Welcome",
    },
    description: {
      uz: "Xush kelibsiz",
      ru: "Privet",
      en: "Welcome",
    },
  };

  const notifications = useGetNotifications();
  const myNotification = useMyNotifications();
  const sendNotification = useSendNotification();

  useEffect(() => {
    notifications.execute({ force: true });
    myNotification.execute({ force: true });
  }, []);

  return (
    <PageContainer title={"NotificationPage"}>
      <Row gutter={[10, 10]}>
        {["uz", "ru", "en"].map((lang) => (
          <Col span={4}>
            <Card size="small" title={data.title?.[lang]}>
              {data.description?.[lang]}
            </Card>
          </Col>
        ))}
        <Col span={24}>
          <Button
            type="primary"
            onClick={() => {
              sendNotification.execute({ data });
            }}
          >
            Send
          </Button>
        </Col>
      </Row>
      <pre>{JSON.stringify(data)}</pre>
      NotificationPage
    </PageContainer>
  );
}

export default NotificationPage;
