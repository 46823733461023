import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import { Button, Dropdown, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useGetStation } from "../../zustand/store";
import DeleteStationModal from "../../components/modal/deleteStationModal";

const columns = [
  "id",
  "serial_number",
  "capacity_of_evc",
  // "images",
  "idle_charger_fee",
  "electricity_fee",
  "ocpp_id",
  //   "cpo_app_link",
  //   "partnership",
  //   "active",
  "stationId",
  //   "sessionStartedAt",
  //   "sessionId",
  //   "password",
  "status",
  //   "lastHeartbeatAt",
  //   "stationBootData",
  "idTag",
  "action",
];

function SingleChargingPoint() {
  const navigate = useNavigate();
  const { cpoId, cpId } = useParams();
  const [deleteModal, setDeleteModal] = useState({ open: false });
  const station = useGetStation();

  useEffect(() => {
    station.execute({ force: true, params: { cpId, cpoId: 16 } });
  }, []);

  return (
    <PageContainer
      title={<LangText id="chargingPoint" />}
      rightPart={
        <>
          <Button
            type="primary"
            onClick={() => {
              navigate(`/cpo/${cpoId}/charging-point/${cpId}/station/create`);
            }}
          >
            <LangText id="createStation" />
          </Button>
        </>
      }
    >
      <Table
        columns={columns.map((c) => ({
          key: c,
          dataIndex: c,
          title: <LangText id={c} />,
        }))}
        dataSource={station?.data?.list?.map((v) => {
          const {
            id,
            serial_number,
            capacity_of_evc,
            images,
            idle_charger_fee,
            electricity_fee,
            ocpp_id,
            cpo_app_link,
            partnership,
            active,
            stationId,
            sessionStartedAt,
            sessionId,
            password,
            status,
            lastHeartbeatAt,
            stationBootData,
            idTag,
          } = v;
          return {
            id,
            serial_number,
            capacity_of_evc,
            // images,
            idle_charger_fee,
            electricity_fee,
            ocpp_id,
            stationId,
            status,
            idTag,
            action: (
              <div>
                <Dropdown
                  arrow={true}
                  menu={{
                    items: [
                      {
                        key: "view",
                        label: (
                          <div
                            onClick={() => {
                              navigate(
                                `/cpo/${cpoId}/charging-point/${cpId}/station/${id}`
                              );
                            }}
                          >
                            <LangText id={"view"} />
                          </div>
                        ),
                      },
                      {
                        key: "edit",
                        label: (
                          <div
                            onClick={() => {
                              navigate(
                                `/cpo/${cpoId}/charging-point/${cpId}/station/${id}/edit`
                              );
                            }}
                          >
                            <LangText id={"edit"} />
                          </div>
                        ),
                      },
                      {
                        key: "delete",
                        label: (
                          <div
                            onClick={() => {
                              setDeleteModal({ open: true, data: v });
                            }}
                          >
                            <LangText id={"delete"} />
                          </div>
                        ),
                      },
                    ],
                  }}
                >
                  <Button>action</Button>
                </Dropdown>
              </div>
            ),
          };
        })}
      />
      <DeleteStationModal
        {...deleteModal}
        onCancel={() => setDeleteModal({ open: false })}
      />
    </PageContainer>
  );
}

export default SingleChargingPoint;
