import { Button, Col, Drawer, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { LangText } from "../../../components/langManagement";
import UploadSingleImage from "../../../components/uploadImage/uploadSingleImage";
import { useAddNearby, useEditNearby } from "../../../zustand/store";

function NearbyForm({ open, onClose, data, edit }) {
  const [file, setFile] = useState({});
  const [form] = Form.useForm();

  const addNearby = useAddNearby();
  const editNearby = useEditNearby();

  useEffect(() => {
    if (edit) {
      console.log(data);
      setFile({ id: data?.image });
      form.setFieldsValue({
        name: data?.name || "",
      });
    }
  }, [open]);
  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        form.resetFields();
        setFile({});
      }}
    >
      <Row gutter={[20, 20]}>
        <Col>
          <UploadSingleImage file={file} setFile={setFile} />
        </Col>
      </Row>
      <br />
      <Form
        form={form}
        layout="vertical"
        onFinish={(e) => {
          console.log(e);
          (edit ? editNearby : addNearby).execute({
            params: { id: data?.id },
            data: { ...e, image: file },
            onSuccess: () => {
              form.resetFields();
              setFile({});
              onClose();
            },
          });
        }}
      >
        <Form.Item
          label={<LangText id="name" />}
          required
          rules={[{ required: true, message: "Please enter plug name" }]}
          name={"name"}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button disabled={!(file.id || file.base64)} htmlType="submit">
            <LangText id={edit ? "edit" : "add"} />
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default NearbyForm;
