import { Button, Card, Col, Form, Input, Modal, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useGetPlug } from "../../../zustand/store";
import { PlugCard } from "./styles";
import { BASE_URL } from "../../../constants/link";
import { LangText } from "../../langManagement";

function NewConnectorModal({ open, onCancel, onSuccess }) {
  const plug = useGetPlug();
  const [chosenPlug, setChosenPlug] = useState();

  useEffect(() => {
    if (open) {
      plug.execute();
      setChosenPlug();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      centered
      title={<LangText id="addConnector" />}
      footer={null}
    >
      <Row gutter={[10, 10]}>
        {plug.data?.map(({ id, name, image, icon }) => (
          <Col>
            <PlugCard
              active={id === chosenPlug?.id}
              size="small"
              onClick={() => {
                if (chosenPlug?.id === id) {
                  setChosenPlug();
                } else {
                  setChosenPlug({ id, name, image, icon });
                }
              }}
            >
              <img src={`${BASE_URL}/file/${icon}`} alt="" />
              <div>{name}</div>
            </PlugCard>
          </Col>
        ))}
      </Row>
      {chosenPlug?.id ? (
        <>
          <br />
          <Form
            layout="vertical"
            onFinish={(e) => {
              onSuccess({ ...e, plug: chosenPlug });
            }}
          >
            <Form.Item
              label={<LangText id="connectorId" />}
              name={"connectorId"}
              required
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<LangText id="idTag" />}
              name={"idTag"}
              required
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<LangText id="capacity" />}
              name={"capacity"}
              required
              rules={[{ required: true }]}
            >
              <Input addonAfter="W" />
            </Form.Item>
            <Form.Item
              label={<LangText id="price" />}
              name={"price"}
              required
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"type"}
              label={<LangText id="type" />}
              required
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={"AC"}>
                  <LangText id="slow(AC)" />
                </Radio>
                <Radio value={"DC"}>
                  <LangText id="fast(DC)" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <LangText id="add" />
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
}

export default NewConnectorModal;
