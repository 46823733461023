import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetChargingPointList, useGetSingleCPO } from "../../zustand/store";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import StationPage from "../stationPage";
import { Button, Dropdown, Table } from "antd";
import { displayAddress } from "../../utils/address.helper";
import dayjs from "dayjs";

const columns = [
  "address",
  // "geoLocation",
  "parkingType",
  "workHours",
  "parkingFeeWhileCharging",
  "withUmbrella",
  "nearby",
  "action",
  // "verified",
  // "active",
];

function SingleCPOPage() {
  const { cpoId } = useParams();

  const singleCPO = useGetSingleCPO();
  const navigate = useNavigate();

  const getChargingPoint = useGetChargingPointList();

  useEffect(() => {
    getChargingPoint.execute({ params: { cpoId } });
    singleCPO.execute({ params: { id: cpoId }, force: true });
  }, []);
  return (
    <PageContainer
      title={
        <>
          <LangText id="cpo" />: {singleCPO?.data?.name}
        </>
      }
      rightPart={
        <>
          <Button
            onClick={() => {
              navigate(`/cpo/${cpoId}/charging-point/create`);
            }}
          >
            createCharingPoint
          </Button>
        </>
      }
    >
      <Table
        columns={columns.map((column) => ({
          key: column,
          dataIndex: column,
          title: <LangText id={column} />,
        }))}
        dataSource={getChargingPoint.data?.list?.map((v) => {
          const {
            address,
            geoLocation,
            parkingType,
            workHours,
            parkingFeeWhileCharging,
            withUmbrella,
            nearby,
            nearby_locations,
            verified,
            active,
            id,
          } = v;
          return {
            address: <>{displayAddress({ address, ...geoLocation })}</>,
            // geoLocation,
            parkingType,
            workHours: (
              <>
                {workHours.from === "0:0" && workHours.to === "23:59" ? (
                  <>24/7</>
                ) : (
                  <>
                    {dayjs(workHours.from, "HH:mm").format("HH:mm")} -{" "}
                    {dayjs(workHours.to, "HH:mm").format("HH:mm")}
                  </>
                )}
              </>
            ),
            parkingFeeWhileCharging: (
              <>
                {parkingFeeWhileCharging === 0 ? (
                  <LangText id="free" />
                ) : (
                  parkingFeeWhileCharging
                )}
              </>
            ),
            withUmbrella: <LangText id={withUmbrella ? "yes" : "no"} />,
            nearby: nearby_locations?.length,
            verified,
            action: (
              <>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "view",
                        label: (
                          <div
                            onClick={() => {
                              navigate(`/cpo/${cpoId}/charging-point/${id}`);
                            }}
                          >
                            <LangText id={"view"} />
                          </div>
                        ),
                      },
                      {
                        key: "edit",
                        label: (
                          <div
                            onClick={() => {
                              navigate(
                                `/cpo/${cpoId}/charging-point/${id}/edit`
                              );
                            }}
                          >
                            <LangText id={"edit"} />
                          </div>
                        ),
                      },
                      {
                        key: "delete",
                        label: (
                          <div
                            onClick={() => {
                              // setDeleteModal({ open: true, data: v });
                            }}
                          >
                            <LangText id={"delete"} />
                          </div>
                        ),
                      },
                    ],
                  }}
                >
                  <Button>
                    <LangText id="action" />
                  </Button>
                </Dropdown>
              </>
            ),
            // active,
          };
        })}
      />
      {/* <StationPage /> */}
    </PageContainer>
  );
}

export default SingleCPOPage;
