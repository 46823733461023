import React, { useEffect, useState } from "react";
import { useGetCpoTransactions } from "../../zustand/store";
import { Table, Card } from "antd";
import { PageContainer } from "../../components/container";
import dayjs from "dayjs";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { httpRequest } from "../../utils/httpRequest";

const dataList = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function SingleTransaction({ record }) {
  const initial = {
    loading: false,
    error: false,
    success: false,
    data: null,
  };
  const [response, setResponse] = useState(initial);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    setResponse(initial);
    try {
      const res = await httpRequest({
        method: "GET",
        // data,
        path: "cpo/meter-value",
        // url,
        params: { id: record?.payload?.transactionId },
        // headers,
      });
      const data = res.data;
      setResponse({
        ...initial,
        success: true,
        data,
      });

      const result = data?.reduce((t, { payload, createdAt }, index) => {
        const sampledValue = payload?.meterValue?.[0]?.sampledValue;
        const sv = sampledValue?.reduce(
          (t, v) => ({
            ...t,
            [v.measurand]:
              v.measurand === "Energy.Active.Import.Register"
                ? (Number(v.value) - Number(record?.payload?.meterStart)) / 1000
                : v.measurand === "Energy.Active.Import.Interval"
                ? Number(v.value) / 1000
                : Number(v.value),
          }),
          {}
        );
        // console.log([...t, sv], index);
        return [
          ...t,
          {
            ...sv,
            Power: Number(
              (
                (Number(sv["Current.Import"]) * Number(sv["Voltage"])) /
                1000
              ).toFixed(2)
            ),
            name: dayjs(createdAt).format("HH:mm"),
          },
        ];
      }, []);
      console.log({ result });
      setData(result);
    } catch (err) {
      console.log({ err });
      setResponse({
        ...initial,
        error: true,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {response.success ? (
        <ResponsiveContainer width="100%" height={800}>
          <LineChart
            // width={500}
            // height={300}
            data={data}
            // margin={{
            //   top: 5,
            //   right: 30,
            //   left: 20,
            //   bottom: 5,
            // }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip />
            <Legend />
            {[
              { name: "Current.Import", color: "red", side: "left" },
              {
                name: "Energy.Active.Import.Interval",
                color: "black",
                side: "right",
              },
              {
                name: "Energy.Active.Import.Register",
                color: "lightgrey",
                side: "right",
              },
              { name: "SoC", color: "lightgreen", side: "right" },
              // { name: "Temperature", color: "lightblue", side: "right" },
              { name: "Voltage", color: "blue", side: "left" },
              { name: "Power", color: "green", side: "right" },
            ].map(({ name, color, side }) => (
              <Line
                yAxisId={side || "left"}
                type="monotone"
                dataKey={name}
                stroke={color || "#8884d8"}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        // <></>
        <></>
      )}
    </>
  );
}

function AnalyzeMeterValue() {
  const transactions = useGetCpoTransactions();

  const data = transactions.data;
  const page = Number(data?.page) || 0;
  const limit = Number(data?.limit) || 0;
  const count = Number(data?.count) || 0;

  useEffect(() => {
    transactions.execute({ params: { cpoId: "22", limit: "50" } });
  }, []);
  return (
    <PageContainer title={"Analyze"}>
      <Table
        size="small"
        pagination={{
          current: page,
          onChange: (e) => {
            console.log(e);
            transactions?.execute({
              force: true,
              params: { page: e, limit },
            });
          },
          pageSize: limit,
          total: count,
        }}
        columns={[
          "id",
          "cpo",
          "initialSOC",
          "soc",
          "meterStart",
          "meterStop",
          "createdAt",
        ].map((c) => ({
          key: c,
          dataIndex: c,
          title: c,
        }))}
        dataSource={
          transactions.data?.list?.map((v, index) => ({
            ...v,
            ...v.payload,
            cpo: v.cpo?.name,
            createdAt: dayjs(v.createdAt).format("DD/MM HH:mm"),
            // key: v.id,
            // id: (
            //   <>
            //     {index + 1} #{v.id}
            //   </>
            // ),
          })) || []
        }
        loading={transactions.loading}
        expandable={{
          expandedRowRender: (record) => (
            <Card size="small">
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Distinctio dolorem alias odio esse blanditiis minus, delectus in
              cum. Optio reprehenderit obcaecati, porro saepe asperiores ab
              illum natus ad officia quaerat! */}
              <SingleTransaction record={record} />
            </Card>
          ),
          defaultExpandAllRows: true,
          // defaultExpandedRowKeys:
          //   transactions.data?.list?.map(({ id }) => id) || [],
          // // expandIcon:()=>false
        }}
      />
    </PageContainer>
  );
}

export default AnalyzeMeterValue;
