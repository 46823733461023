import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import MapBox from "../../components/mapBox";
import UploadGroupImage from "../../components/uploadImage/uploadGroupImage";
import Socket from "./socket";
import {
  useAddStation,
  useEditStation,
  useGetSingleStation,
} from "../../zustand/store";
import { useNavigate, useParams } from "react-router-dom";

function FormChargingStation() {
  const [markerCoords, setMarkerCoords] = useState({});
  const [connectorList, setConnectorList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const addStation = useAddStation();
  const editStation = useEditStation();
  const singleStation = useGetSingleStation();
  const { sId, cpId, cpoId } = useParams();
  const edit = Boolean(sId);

  useEffect(() => {
    if (sId) {
      singleStation.execute({
        params: { id: sId },
        force: true,
        onSuccess: (res) => {
          const data = res.data;
          console.log(data);
          setConnectorList(data.connectors);
          setFileList(data.images.map((id) => ({ id })));
          form.setFieldsValue({
            // serial_number: data.serial_number,
            ...data,
          });
        },
      });
    }
  }, []);

  return (
    <PageContainer title={<LangText id="newStation" />}>
      <Form
        form={form}
        layout="vertical"
        onFinish={(e) => {
          (edit ? editStation : addStation).execute({
            params: { id: sId },
            data: {
              ...e,
              chargingPointId: cpId,
              connectors: connectorList,
              images: fileList,
            },
            onSuccess: () => {
              navigate(`/cpo/${cpoId}/charging-point/${cpId}`);
            },
          });
        }}
      >
        {/* <Form.Item
          required
          name={"serial_number"}
          label={<LangText id="serial_number" />}
        >
          <Input />
        </Form.Item> */}
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item
              required
              name={"idle_charger_fee"}
              label={<LangText id="idleFee" />}
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name={"electricity_fee"}
              label={<LangText id="electricityFee" />}
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name={"ocpp_id"}
              label={<LangText id="ocppId" />}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name={"stationId"}
              label={<LangText id="stationId" />}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required
              name={"idTag"}
              label={<LangText id="idTag" />}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name={"serial_number"}
              label={<LangText id="serialNumber" />}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name={"capacity_of_evc"}
              label={<LangText id="capacity" />}
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} addonAfter="kW" min={0} />
            </Form.Item>
          </Col>
        </Row>

        {/* <Form.Item
          required
          name={"address"}
          label={<LangText id="address" />}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item>
          <MapBox
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
            name=""
          />
        </Form.Item>
        <Form.Item label={<LangText id="addSocket" />}>
          <Socket
            connectorList={connectorList}
            setConnectorList={setConnectorList}
          />
        </Form.Item>
        <Form.Item label={<LangText id="image" />}>
          <UploadGroupImage fileList={fileList} setFileList={setFileList} />
        </Form.Item>
        <Form.Item>
          <Row gutter={[10, 10]}>
            <Col>
              <Button
                onClick={() => {
                  navigate(`/cpo/${cpoId}/charging-point/${cpId}`);
                }}
              >
                <LangText id="cancel" />
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                <LangText id={edit ? "saveChanges" : "create"} />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </PageContainer>
  );
}

export default FormChargingStation;
