import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import {
  useAddCard,
  useCreateCard,
  useDeleteCard,
  useGetCards,
  useGetMe,
  useGetMePerson,
  useGetPaymeHistory,
  useGetTransactions,
  useGetVerifyCode,
  useTopUpPayme,
  useVerifyCard,
} from "../../zustand/store";
import { Button, Card, Col, Form, Input, InputNumber, Row } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

function PaymeCard({ id, color, token, data }) {
  const deleteCard = useDeleteCard();
  const topUp = useTopUpPayme();
  const [form] = Form.useForm();

  return (
    <>
      <Card
        size="small"
        actions={[
          <DeleteOutlined
            onClick={() => {
              deleteCard.execute({ params: { id } });
            }}
          />,
        ]}
      >
        <div>{data?.number}</div>
        <div>{data?.expire}</div>
      </Card>
      <Card size="small">
        <Form
          form={form}
          onFinish={(e) => {
            console.log(e);
            topUp.execute({
              data: {
                cardId: id,
                amount: e?.amount,
              },
            });
          }}
        >
          <Form.Item name="amount">
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit">Pay</Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

function PaymePage() {
  const createCard = useCreateCard();
  const verifyCard = useVerifyCard();
  const verificationCode = useGetVerifyCode();

  const addCard = useAddCard();
  const getPaymeHistory = useGetPaymeHistory();
  const getCards = useGetCards();
  const transactions = useGetTransactions();

  const getMe = useGetMe();

  const [formCard] = Form.useForm();
  const [formVerify] = Form.useForm();

  useEffect(() => {
    // createCard.execute();
    getCards.execute({ force: true });
    transactions.execute({ force: true });
    getMe.execute({ force: true });
    getPaymeHistory.execute({
      force: true,
      params: {
        page: "1",
        limit: "10",
        // paymeCardId: "62",
        type: "EXPENSE",
      },
      // params:{type:"EXPENSE"}
    });
  }, []);

  const cCard = createCard.data?.result?.card;
  return (
    <PageContainer title={"Payme"}>
      <Row gutter={[10, 10]}>
        <Col span={4}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Card size="small">
                <Form
                  layout="vertical"
                  form={formCard}
                  onFinish={(card) => {
                    console.log(card);
                    createCard.execute({ params: { card, save: true } });
                  }}
                >
                  <Form.Item name="number" label="number">
                    <Input />
                  </Form.Item>
                  <Form.Item name="expire" label="expire">
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Create
                    </Button>
                  </Form.Item>
                </Form>
                <div>{verificationCode?.data?.result?.phone}</div>
                <div>
                  <Button
                    disabled={cCard?.verify !== false}
                    onClick={() => {
                      verificationCode.execute({
                        params: { token: cCard.token },
                      });
                    }}
                  >
                    Send Code
                  </Button>
                </div>
              </Card>
            </Col>
            {/* <Col>
              <Button disabled={cCard?.verify !== false}></Button>
            </Col> */}
            {cCard ? (
              <Col span={24}>
                <Card size="small">
                  <div>{cCard?.number}</div>
                  <div>{cCard?.expire}</div>
                  <div>{cCard?.token}</div>
                </Card>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Col>
        <Col>
          <Card>
            <Form
              form={formVerify}
              layout="vertical"
              onFinish={({ code }) => {
                verifyCard.execute({
                  params: {
                    token: cCard?.token,
                    code,
                  },
                  onSuccess: (res) => {
                    console.log("====================================");
                    console.log(res);
                    console.log("====================================");
                    const token = res.data?.result?.card?.token;
                    if (token) {
                      addCard.execute({ data: { token } });
                    }
                  },
                });
              }}
            >
              <Form.Item name="code" label="code">
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={
                    !verificationCode?.data?.result?.sent
                    // cCard?.verify !== false
                  }
                  type="primary"
                  htmlType="submit"
                >
                  Verify Code
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            {getCards.data?.map((v) => (
              <Col span={4}>
                <PaymeCard {...v} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
}

export default PaymePage;
