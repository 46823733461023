import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import MapBox from "./mapBox";
import {
  useGetFavoriteStation,
  useGetFilterItems,
  useGetMap,
  useGetMapStation,
  useGetNearestStation,
  useGetPermissions,
  useGetRoles,
  useToggleFavorite,
  useUserFeedback,
  useUserPackage,
  useUserTransactions,
} from "../../zustand/store";
import { Button, Card, Checkbox, Col, Form, Row, Slider, Table } from "antd";

function MapStation() {
  const [markerCoords, setMarkerCoords] = useState({});

  //   {north, south, west, east} = query?.bounds;
  //     cpo_id
  //     coupons
  //     price
  //     capacity
  //     plugs
  //     nearby

  const [filterState, setFilterState] = useState({});

  const filterItems = useGetFilterItems();
  const permission = useGetPermissions();
  const roles = useGetRoles();
  const favoriteList = useGetFavoriteStation();
  const nearest = useGetNearestStation();
  const toggleFavorite = useToggleFavorite();

  const map = useGetMap();
  const mapStations = useGetMapStation();
  const data = filterItems.data;
  const capacity = data?.capacity;
  const price = data?.price;
  const cpo = data?.cpo;
  const nearby = data?.nearby;
  const plugs = data?.plugs;
  useEffect(() => {
    filterItems.execute();
    mapStations.execute();
    permission.execute();
    roles.execute();
    // transactions.execute({
    //   params: { userId: "a6b01d60-d357-4428-a4bd-aad2653e50e4" },
    //   force: true,
    // });
    // feedbacks.execute({
    //   params: { userId: "370128e9-f7ac-4bf7-9188-153a153d5e4a" },
    //   force: true,
    // });
    // packages.execute({
    //   params: { userId: "370128e9-f7ac-4bf7-9188-153a153d5e4a" },
    //   force: true,
    // });
  }, []);

  const changeFilterState = (obj) => {
    setFilterState((prev) => ({ ...prev, ...obj }));
    // setTimeout(() => {
    // //   map.execute({ params: filterState,force:true });
    // }, 100);
  };

  useEffect(() => {
    if (Object.values(filterState).length) {
      map.execute({
        params: {
          ...filterState,
          location: { ...markerCoords },
          bounds: undefined,
          radius: 50,
          // limit: 50,
        },
        force: true,
      });
    }
  }, [filterState]);

  return (
    <PageContainer title={"Map"}>
      <Row gutter={[10, 10]}>
        <Col span={16}>
          <Button
            onClick={() => {
              favoriteList.execute({ force: true });
            }}
          >
            Favorite List
          </Button>
          <MapBox
            changeFilterState={changeFilterState}
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
          />
          {filterItems.data ? (
            <Form layout="vertical">
              <Form.Item label="capacity">
                <Slider
                  onChangeComplete={([min, max]) => {
                    // console.log(min, max);
                    changeFilterState({ capacity: { min, max } });
                  }}
                  range
                  defaultValue={[capacity?.min, capacity?.max]}
                  min={capacity?.min}
                  max={capacity?.max}
                />
              </Form.Item>
              <Form.Item label="price">
                <Slider
                  onChangeComplete={([min, max]) => {
                    // console.log(min, max);
                    changeFilterState({ price: { min, max } });
                  }}
                  range
                  defaultValue={[price?.min, price?.max]}
                  min={price?.min}
                  max={price?.max}
                />
              </Form.Item>
              <Form.Item label="CPO">
                <Checkbox.Group
                  onChange={(cpo_id) => {
                    changeFilterState({ cpo_id });
                  }}
                >
                  {cpo?.map(({ id, name }) => (
                    <Checkbox value={id}>{name}</Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
              <Form.Item label="nearby">
                <Checkbox.Group
                  onChange={(nearby) => {
                    changeFilterState({ nearby });
                  }}
                >
                  <Row gutter={[0, 10]}>
                    {nearby?.map(({ id, name }) => (
                      <Col>
                        <Checkbox value={id}>{name}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item label="Plugs">
                <Checkbox.Group
                  onChange={(plugs) => {
                    changeFilterState({ plugs });
                  }}
                >
                  {plugs?.map(({ id, name }) => (
                    <Checkbox value={id}>{name}</Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Form>
          ) : (
            <></>
          )}
        </Col>
        <Col span={8}>
          <Card size="small">
            <Table
              size="small"
              columns={[
                // "id",
                "name",
                "action",
              ].map((d) => ({
                key: d,
                dataIndex: d,
                title: d,
              }))}
              dataSource={
                nearest.data?.list.map((d) => ({
                  ...d,
                  action: (
                    <>
                      <Button
                        onClick={() => {
                          toggleFavorite.execute({
                            params: { id: d?.id },
                            onSuccess: () => {
                              nearest.execute({
                                params: {
                                  location: { ...markerCoords },
                                  cpo_id: [],
                                  page: 1,
                                  limit: 10,
                                },
                                force: true,
                              });
                            },
                          });
                        }}
                        type={d.favourite ? "primary" : undefined}
                      >
                        Save
                      </Button>
                    </>
                  ),
                })) || []
              }
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}

export default MapStation;
