import { Col, Input, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import {
  useGetCardDelUsers,
  useGetCardRegUsers,
  useGetCardStationCP_Station,
  useGetCardTopUps,
  useGetOperatorStation,
  useGetStatCPO,
  useGetStatChartByTime,
  useGetStatStation,
  useGetTopCPOs,
  useGetTopStations,
  useGetTopUsers,
} from "../../zustand/store";
import dayjs from "dayjs";

function OperatorStationsPage() {
  const stations = useGetOperatorStation();
  const chartByTime = useGetStatChartByTime();
  const statCPO = useGetStatCPO();
  const statStation = useGetStatStation();

  const topCPOs = useGetTopCPOs();
  const topUsers = useGetTopUsers();
  const topStations = useGetTopStations();

  const regUsers = useGetCardRegUsers();
  const delUsers = useGetCardDelUsers();
  const topUps = useGetCardTopUps();

  const stationCP_CPO = useGetCardStationCP_Station();

  const [text, setText] = useState();

  useEffect(() => {
    const params = {
      from: dayjs().subtract(50, "days").toISOString(),
      to: dayjs().toISOString(),
    };
    stations.execute({ params: { text }, force: true });
    chartByTime.execute({ params });
    statCPO.execute({ params });
    statStation.execute({ params });
    topCPOs.execute({ params });
    topUsers.execute({ params });
    topStations.execute({ params });
    regUsers.execute({ params });
    delUsers.execute({ params });
    topUps.execute({ params });
    stationCP_CPO.execute();
  }, [text]);

  return (
    <PageContainer title="Operator">
      <div>
        <Row>
          <Col span={24}>
            <Input
              value={text}
              onChange={(v) => {
                setText(v.target.value);
              }}
            />
            {/* <Selection/> */}
          </Col>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: "CPO",
                  dataIndex: ["charging_point", "cpo", "name"],
                },
                {
                  title: "Charge point",
                  dataIndex: ["charging_point", "name"],
                },
                {
                  title: "Serial number",
                  dataIndex: "serial_number",
                },
              ]}
              dataSource={stations?.data?.list}
              pagination={{
                total: stations?.count,
                pageSize: stations?.limit,
              }}
            />
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
}

export default OperatorStationsPage;
