import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import { Button, Card, Col, Image, Row } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import PlugForm from "./plugForm";
import { useGetPlug } from "../../zustand/store";
import { BASE_URL } from "../../constants/link";
import SinglePlug from "./singlePlug";

function PlugPage() {
  const [form, setForm] = useState({ open: false });
  const plug = useGetPlug();

  useEffect(() => {
    plug.execute();
  }, []);

  return (
    <PageContainer
      title={<LangText id="plug" />}
      rightPart={
        <>
          <Button type="primary" onClick={() => setForm({ open: true })}>
            <PlusOutlined /> <LangText id="createPlug" />
          </Button>
        </>
      }
    >
      <Row gutter={[10,10]}>
        {plug.data?.map((v) => (
          <Col span={6}>
            <SinglePlug {...v} setForm={setForm} />
          </Col>
        ))}
      </Row>

      <PlugForm
        edit={form?.edit}
        data={form?.data}
        open={form.open}
        onClose={() => setForm({ open: false })}
      />
    </PageContainer>
  );
}

export default PlugPage;
