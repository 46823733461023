import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import { useParams } from "react-router-dom";
import { useGetSingleStation, useGetStation } from "../../zustand/store";

function SingleStationPage() {
  const { sId } = useParams();

  const singleStation = useGetSingleStation();

  useEffect(() => {
    singleStation.execute({ force: true, params: { id: sId } });
  }, []);

  return (
    <PageContainer
      title={
        <>
          <LangText id="station" /> #{sId}
        </>
      }
    >
      SingleStationPage
    </PageContainer>
  );
}

export default SingleStationPage;
