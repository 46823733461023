import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { LangText } from "../../../components/langManagement";
import { useGetCPO } from "../../../zustand/store";
import { useNavigate } from "react-router-dom";

function FilterCPO() {
  const cpo = useGetCPO();

  const [search, setSearch] = useState("");
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      if (search) {
        cpo.execute({
          params: {
            searchName: search,
          },
          force: true,
          //   onSuccess: (res) => {
          //     const data = res.data;
          //     if (data?.length > 0) {
          //       setSelectedAddress(data[0]);
          //     } else {
          //       setSelectedAddress({});
          //     }
          //   },
        });
      } else {
        cpo.execute({
          force: true,
          params: {
            sort: { name: "cpCount", type: "DESC" },
          },
        });
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [search]);

  const navigate = useNavigate();

  return (
    <Row gutter={[10, 10]} justify={"space-between"}>
      <Col>
        <Input
          onChange={(e) => {
            setSearch(e.target.value);
            console.log(e.target.value);
          }}
          addonBefore={
            <>
              <SearchOutlined />
            </>
          }
          placeholder={LangText({ id: "searchByCPOName" })}
        />
      </Col>
      <Col>
        <Row gutter={[10, 10]}>
          <Col>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "direct",
                    label: (
                      <>
                        <LangText id="direct" />
                      </>
                    ),
                  },
                  {
                    key: "indirect",
                    label: (
                      <>
                        <LangText id="indirect" />
                      </>
                    ),
                  },
                ],
              }}
            >
              <Button>
                <LangText id="partnershipStatus" />
              </Button>
            </Dropdown>
          </Col>
          <Col>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <>
                        <LangText id="1-500" />
                      </>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <>
                        <LangText id="500-1" />
                      </>
                    ),
                  },
                ],
              }}
            >
              <Button>
                <LangText id="qntyChargers" />
              </Button>
            </Dropdown>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                navigate("/cpo/create");
                // setForm({ open: true })
              }}
            >
              <PlusOutlined /> <LangText id="createCPO" />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FilterCPO;
