import React from "react";

function Navbar() {
  return (
    <div>
      Navbar Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
      doloribus dolorum iure provident labore nulla molestiae, quia magnam
      consequatur sed similique quidem aspernatur rem quisquam, veniam quod
      velit officia incidunt!
    </div>
  );
}

export default Navbar;
