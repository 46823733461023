import styled from "styled-components";

export const Wrapper = styled.div``;

export const SocketCard = styled.div`
  width: 230px;
  height: 108px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  padding: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  .title {
    display: flex;
    justify-content: space-between;
    .text {
      color: #22252d;

      /* font-family: "Proxima Nova"; */
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.28px;
    }
  }
  .capacity {
    /* margin-top: 7px; */
    color: #2e2efe;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .icon {
    width: 36px;
    height: 36px;
    margin-top: auto;
    margin-bottom: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .socket-img{
    height: 70px;
    position: absolute;
    bottom: 0;
    right: 0;
    img{
        width: auto;
        height: 100%;
    }
  }
`;

export const NewSocket = styled.div`
  height: 100px;
  display: flex;
  /* align-items: "center"; */

  div {
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
`;
