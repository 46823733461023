import styled from "styled-components";

export const AddImgBox = styled.label`
  width: 80px;
  height: 80px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 25px;
  display: flex;
  justify-content: center;
  img {
    /* width: 100%; */
    height: 100%;
  }
  input {
    display: none !important;
  }
`;

export const ImageWrapper = styled.div`
  /* max-width: 150px; */
  width: 100px;
  height: 100px;
  border-radius: 5px;
  /* border: 1px solid black; */
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  .ant-image img {
    height: 100%;
  }
`;
