import L, { marker } from "leaflet"; // Import Leaflet library
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import {
  useGetCP_ById,
  useGetMap,
  useGetMe,
  useGetNearestStation,
  useToggleFavorite,
} from "../../../zustand/store";
import "./style.css";
import { Wrapper } from "./styles";
import { httpRequest } from "../../../utils/httpRequest";

// URL to the custom marker icon image
const markerIconUrl =
  "https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png";

// Create a custom icon using the image URL
// const customIcon = L.icon({
const customIcon = (name) =>
  L.divIcon({
    // iconUrl: markerIconUrl,
    className: "custom-icon",
    html: `<img style="width:100%;height:100%" src="https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png"/>${
      name ? `<div class="box">${name}</div>` : ""
    }`,
    iconSize: [25, 41], // size of the icon
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  });

const redIcon = new L.Icon({
  iconUrl:
    "https://api.geoapify.com/v1/icon/?type=material&color=red&size=large&icon=home&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const redIconNumber = (number) =>
  new L.Icon({
    iconUrl: `https://api.geoapify.com/v1/icon/?text=${number}&type=material&color=red&size=large&icon=home&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

const greenIcon = new L.Icon({
  iconUrl:
    "https://api.geoapify.com/v1/icon/?type=material&color=green&size=large&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function MyComponent({
  currentCoords,
  markerCoords,
  setMarkerCoords,
  setCurrentCoords,
  changeFilterState,
}) {
  const nearest = useGetNearestStation();
  const me = useGetMe();
  const map = useMapEvents({
    click: (res) => {
      map.locate();
      setCurrentCoords(res?.latlng);
      setMarkerCoords(res?.latlng);
      nearest.execute({
        params: {
          location: { ...res?.latlng },
          cpo_id: [],
          page: 1,
          limit: 10,
        },
        force: true,
      });
    },
  });
  useEffect(() => {
    if (markerCoords && Object.keys(markerCoords).length) {
      map?.setView(markerCoords, map?.getZoom(), { animate: true });
    }
    const restaurant = me?.data?.restaurant;
    if (restaurant?.coords && !markerCoords) {
      setMarkerCoords(restaurant?.coords);
    }
  }, []);

  useEffect(() => {
    const handleViewportChanged = () => {
      // setBounds(map.getBounds());
      const bounds = map.getBounds();
      const north = bounds.getNorth().toString();
      const south = bounds.getSouth().toString();
      const east = bounds.getEast().toString();
      const west = bounds.getWest().toString();

      console.log({ bounds: { north, south, east, west } });
      changeFilterState({
        bounds: { north, south, east, west },
        location: currentCoords,
      });
    };

    map.on("moveend", handleViewportChanged);

    return () => {
      map.off("moveend", handleViewportChanged);
    };
  }, [map]);

  return null;
}

function RouteFromTo({ geoLocation, markerCoords }) {
  const [line, setLine] = useState([]);
  useEffect(() => {
    httpRequest({
      method: "GET",
      url: `https://api.mapbox.com/directions/v5/mapbox/driving/${geoLocation?.lng}%2C${geoLocation?.lat}%3B${markerCoords?.lng}%2C${markerCoords?.lat}`,
      params: {
        alternatives: false,
        geometries: "geojson",
        language: "en",
        overview: "simplified",
        steps: false,
        notifications: "none",
        access_token:
          "pk.eyJ1IjoidG1iMDAxIiwiYSI6ImNraG5iYWdqMTJkdzkyenFxNDB3eG1nMzEifQ.jzmCSJoE1NpB1NoJ_5uPqQ",
      },
    })
      .then((res) => {
        const data = res?.data;
        const polygon = data?.routes?.[0]?.geometry?.coordinates;
        console.log(res?.data?.routes?.[0]?.geometry?.coordinates);
        setLine(polygon.map((l) => [...l].reverse()));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [geoLocation]);

  return (
    <>
      {line?.length ? (
        <Polyline pathOptions={{ color: "red" }} positions={line} />
      ) : (
        <></>
      )}
    </>
  );
}

function MapBox({ markerCoords, setMarkerCoords, name, changeFilterState }) {
  const [currentCoords, setCurrentCoords] = useState({
    // lat: 33.7111401,
    // lng: -78.8920569,
    lat: 41.32474808964131,
    lng: 69.2386658053869,
  });
  const me = useGetMe();
  const mapData = useGetMap();
  const getCPById = useGetCP_ById();
  const restaurantCoords = me?.data?.restaurant?.coords;
  const toggleFavorite = useToggleFavorite();
  const nearest = useGetNearestStation();

  useEffect(() => {
    markerCoords && setCurrentCoords(markerCoords);
  }, []);

  return (
    <Wrapper>
      <div style={{ width: "100%", height: "600px" }}>
        {/* {currentCoords?.lat} {currentCoords?.lng} */}
        <MapContainer
          style={{ width: "100%", height: "100%" }}
          center={currentCoords}
          zoom={13}
          scrollWheelZoom
          onViewportChanged={(e) => {
            console.log(e);
          }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {restaurantCoords ? (
            <Marker position={restaurantCoords} icon={redIcon} />
          ) : (
            <></>
          )}
          {Object.keys(markerCoords)?.length ? (
            <Marker
              draggable
              position={markerCoords}
              icon={greenIcon}
              eventHandlers={{
                dragend: (res) => {
                  setMarkerCoords(res.target.getLatLng());
                },
              }}
            />
          ) : (
            <></>
          )}

          {/* {mapData.data?.list?.map(({ id, geoLocation }) => (
            <Marker
              draggable
              position={
                geoLocation
                // markerCoords
              }
              icon={customIcon(name)}
              eventHandlers={{
                click: () => {
                  console.log("CLICKED");
                  getCPById.execute({ params: { id } });
                  toggleFavorite.execute({ params: { id } });
                },
                // dragend: (res) => {
                //   setMarkerCoords(res.target.getLatLng());
                // },
              }}
            />
          ))} */}
          {nearest.data?.list?.map(({ id, geoLocation }, index) => (
            <>
              <Marker
                draggable
                position={
                  geoLocation
                  // markerCoords
                }
                icon={redIconNumber(index + 1)}
                eventHandlers={{
                  click: () => {
                    console.log("CLICKED");
                    getCPById.execute({ params: { id } });
                    toggleFavorite.execute({ params: { id } });
                  },
                  // dragend: (res) => {
                  //   setMarkerCoords(res.target.getLatLng());
                  // },
                }}
              />
              <RouteFromTo
                geoLocation={geoLocation}
                markerCoords={markerCoords}
              />
            </>
          ))}

          <MyComponent
            currentCoords={currentCoords}
            setCurrentCoords={setCurrentCoords}
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
            changeFilterState={changeFilterState}
          />
        </MapContainer>
      </div>
    </Wrapper>
  );
}

export default MapBox;
