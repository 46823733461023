import styled from "styled-components"

export const AddImgBox = styled.label`
  width: 150px;
  height: 150px;
  border-radius: 5px;
  border: 1px solid black;
  /* padding: 25px; */
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    /* width: 100%; */
    height: 100%;
  }
  input {
    display: none !important;
  }
`;


export const ImageBox = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 5px;
  /* border: 1px solid black; */
  /* padding: 5px; */
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;