import { create } from "zustand";
import { httpRequest } from "../utils/httpRequest";
import { message } from "antd";
import { dateOptions } from "../constants/date";
const initialState = {
  loading: false,
  success: false,
  error: false,
  data: null,
  errorData: null,
};

export const logout = () => {
  localStorage.removeItem("bearer_token");
  window.location.reload();
};
export const useLangStore = create((set) => ({
  lang: "EN",
  changeLang: (l) => {
    set({ lang: l });
  },
}));

export const useSidebar = create((set, get) => ({
  status: false,
  open: () => {
    set({ status: true });
  },
  close: () => {
    set({ status: false });
  },
  toggle: () => {
    set({ status: !get().status });
  },
}));

export const useDateRange = create((set) => ({
  dateRange: dateOptions[3]?.range,
  setDateRange: (date) => {
    set({ dateRange: date });
  },
}));

const request = (props, option) =>
  create((set, get) => ({
    ...initialState,
    execute: (p) => {
      if (props.method === "GET" && !p?.force && Boolean(get().data)) {
        return;
      }
      set({ ...initialState, loading: true });
      httpRequest({
        ...p,
        ...props,
        data: { ...props?.data, ...p?.data },
        params: { ...props?.params, ...p?.params },
      })
        .then((res) => {
          set({ ...initialState, success: true, data: res.data });
          p?.onSuccess?.(res);
          option?.onSuccess?.(res);
          // console.log("%cSuccess request: ", "color:green;", res.data);
        })
        .catch((err) => {
          set({ ...initialState, error: true, errorData: err });
          p?.onError?.(err);
          option?.onError?.(err);
          // console.log("%Error request: ", "color:green;", err);
          if (
            !p?.onError &&
            !option?.onError &&
            typeof err?.response?.data === "string"
          ) {
            message.error(err?.response?.data);
          }
        })
        .finally(() => {
          p?.onFinal?.();
          option?.onFinal?.();
        });
      // }
    },
    clean: () => {
      set(initialState);
    },
  }));

const requestList = (props, option) =>
  create((set, get) => ({
    ...initialState,
    execute: (p) => {
      if (props.method === "GET" && !p?.force && Boolean(get().data)) {
        return;
      }
      console.log("====================================");
      console.log(p);
      console.log("====================================");
      set({ ...initialState, data: get().data, loading: true });
      httpRequest({
        ...p,
        ...props,
        data: { ...props?.data, ...p?.data },
        params: { ...props?.params, ...p?.params },
      })
        .then((res) => {
          set({ ...initialState, success: true, data: res.data });
          p?.onSuccess?.(res);
          option?.onSuccess?.(res);
          // console.log("%cSuccess request: ", "color:green;", res.data);
        })
        .catch((err) => {
          set({ ...initialState, error: true, errorData: err });
          p?.onError?.(err);
          option?.onError?.(err);
          // console.log("%Error request: ", "color:green;", err);
        })
        .finally(() => {
          p?.onFinal?.();
          option?.onFinal?.();
        });
      // }
    },
    clean: () => {
      set(initialState);
    },
  }));

export const paymeRequest = (data, option) =>
  create((set, get) => ({
    ...initialState,
    execute: (p) => {
      set({ ...initialState, data: get().data, loading: true });
      httpRequest({
        method: "POST",
        url: "https://checkout.test.paycom.uz/api",
        // url: "https://checkout.paycom.uz/api",
        headers: {
          "X-Auth": "5e730e8e0b852a417aa49ceb",
          Authorization: undefined,
        },
        data: {
          id: parseInt(Math.random() * 1_000_000_000),
          ...data,
          params: { ...p?.params },
        },
      })
        .then((res) => {
          set({ ...initialState, success: true, data: res.data });
          p?.onSuccess?.(res);
          option?.onSuccess?.(res);
          // console.log("%cSuccess request: ", "color:green;", res.data);
        })
        .catch((err) => {
          set({ ...initialState, error: true, errorData: err });
          p?.onError?.(err);
          option?.onError?.(err);
          // console.log("%Error request: ", "color:green;", err);
        })
        .finally(() => {
          p?.onFinal?.();
          option?.onFinal?.();
        });
      // }
    },
    clean: () => {
      set(initialState);
    },
  }));

export const useCreateCard = paymeRequest({
  method: "cards.create",
});

export const useGetVerifyCode = paymeRequest({
  method: "cards.get_verify_code",
});

export const useVerifyCard = paymeRequest({
  method: "cards.verify",
});

export const useGetMePerson = request({
  method: "GET",
  path: "user/me",
});
export const useGetMe = request({
  method: "GET",
  path: "user/cpo/me",
});
export const useGetBalanceData = request({
  method: "GET",
  path: "user/balance-data",
});

export const useGetProfile = request({
  method: "GET",
  path: "user/profile",
});

export const useGetSingleCPO = requestList({
  method: "GET",
  path: "cpo/single",
});

export const useGetCPO = requestList({
  method: "GET",
  path: "cpo",
});

export const useCPOListServerMigration = requestList({
  method: "GET",
  path: "cpo/server-migration",
});

export const useAddCPO = request(
  {
    method: "POST",
    path: "cpo",
  },
  {
    onSuccess: () => {
      useGetCPO.getState().execute({ force: true });
    },
  }
);
export const useEditCPO = request(
  {
    method: "PUT",
    path: "cpo",
  },
  {
    onSuccess: () => {
      useGetCPO.getState().execute({ force: true });
    },
  }
);

export const useDeleteCPO = request(
  {
    method: "DELETE",
    path: "cpo",
  },
  {
    onSuccess: () => {
      useGetCPO.getState().execute({ force: true });
    },
  }
);

export const useDeleteFile = request({
  method: "DELETE",
  path: "file",
});

export const useGetPlug = requestList({
  method: "GET",
  path: "plug",
});

export const useAddPlug = request(
  {
    method: "POST",
    path: "plug",
  },
  {
    onSuccess: () => {
      useGetPlug.getState().execute({ force: true });
    },
  }
);

export const useEditPlug = request(
  {
    method: "PUT",
    path: "plug",
  },
  {
    onSuccess: () => {
      useGetPlug.getState().execute({ force: true });
    },
  }
);

export const useDeletePlug = request(
  {
    method: "DELETE",
    path: "plug",
  },
  {
    onSuccess: () => {
      useGetPlug.getState().execute({ force: true });
    },
  }
);

export const useGetNearby = requestList({
  method: "GET",
  path: "nearby",
});

export const useAddNearby = request(
  {
    method: "POST",
    path: "nearby",
  },
  {
    onSuccess: () => {
      useGetNearby.getState().execute({ force: true });
    },
  }
);
export const useEditNearby = request(
  {
    method: "PUT",
    path: "nearby",
  },
  {
    onSuccess: () => {
      useGetNearby.getState().execute({ force: true });
    },
  }
);
export const useDeleteNearby = request(
  {
    method: "DELETE",
    path: "nearby",
  },
  {
    onSuccess: () => {
      useGetNearby.getState().execute({ force: true });
    },
  }
);

export const useGetChargingPointList = request({
  method: "GET",
  path: "charging-point",
});

export const useGetSingleCP = request({
  method: "GET",
  path: "charging-point/single",
});

export const useAddChargingPoint = request(
  {
    method: "POST",
    path: "charging-point",
  },
  {
    onSuccess: () => {
      useGetChargingPointList.getState().execute();
    },
  }
);
export const useEditChargingPoint = request(
  {
    method: "PUT",
    path: "charging-point",
  },
  {
    onSuccess: () => {
      useGetChargingPointList.getState().execute();
    },
  }
);

export const useDeleteChargingPoint = request(
  {
    method: "DELETE",
    path: "charging-point",
  },
  {
    onSuccess: () => {
      useGetChargingPointList.getState().execute();
    },
  }
);

export const useGetSingleStation = request({
  method: "GET",
  path: "station/single",
});

export const useGetOperatorStation = request({
  method: "GET",
  path: "operator/stations",
});

export const useGetStatChartByTime = request({
  method: "GET",
  path: "stat/chart/time/kw-money",
});

export const useGetStatCPO = request({
  method: "GET",
  path: "stat/chart/cpo/hour-count",
});

export const useGetStatStation = request({
  method: "GET",
  path: "stat/chart/station/hour-count",
});

export const useGetTopCPOs = request({
  method: "GET",
  path: "stat/top/cpo",
});

export const useGetTopUsers = request({
  method: "GET",
  path: "stat/top/users",
});

export const useGetTopStations = request({
  method: "GET",
  path: "stat/top/stations",
});

export const useGetCardRegUsers = request({
  method: "GET",
  path: "stat/card/reg/users",
});
export const useGetCardDelUsers = request({
  method: "GET",
  path: "stat/card/del/users",
});
export const useGetCardTopUps = request({
  method: "GET",
  path: "stat/card/top-ups",
});
export const useGetCardStationCP_Station = request({
  method: "GET",
  path: "stat/card/station-cp-cpo",
});

export const useGetStation = request({
  method: "GET",
  path: "station",
});

export const useAddStation = request(
  {
    method: "POST",
    path: "station",
  },
  {
    onSuccess: () => {
      useGetStation.getState().execute({ force: true });
    },
  }
);

export const useEditStation = request(
  {
    method: "PUT",
    path: "station",
  },
  {
    onSuccess: () => {
      useGetStation.getState().execute({ force: true });
    },
  }
);

export const useDeleteStation = request(
  {
    method: "DELETE",
    path: "station",
  },
  {
    onSuccess: () => {
      // useGetStation.getState().execute({ force: true });
    },
  }
);

export const useGetRoles = request({
  method: "GET",
  path: "roles",
});

export const useGetPermissions = request({
  method: "GET",
  path: "roles/permissions",
});

export const useGetFilterItems = request({
  method: "GET",
  path: "map/filter-items",
});

export const useGetMap = requestList({
  method: "GET",
  path: "map/filter",
});

export const useGetMapStation = requestList({
  method: "GET",
  path: "map",
});

export const useGetCP_ById = requestList({
  method: "GET",
  path: "map/cp",
});

export const useVerifyPN = request(
  {
    method: "POST",
    path: "auth/login",
  },
  {
    onSuccess: () => {
      useGetUsersForOTP.getState().execute({ force: true });
    },
  }
);

export const useVerifyOTP = request(
  {
    method: "POST",
    path: "auth/verify-otp",
  },
  {
    onSuccess: () => {
      useGetUsersForOTP.getState().execute({ force: true });
    },
  }
);

export const useGetUsersForOTP = requestList({
  method: "GET",
  path: "user/all",
});
export const useGetFeedbackOption = requestList({
  method: "GET",
  path: "feedback/option",
});
export const useGetreportOption = requestList({
  method: "GET",
  path: "report/option",
});

export const useGetUserMe = requestList({
  method: "GET",
  path: "user/me",
});

export const useDeleteUser = request({
  method: "POST",
  path: "auth/delete",
});

export const useGetNearestStation = requestList({
  method: "GET",
  path: "map/nearest",
});

export const useGetFavoriteStation = request({
  method: "GET",
  path: "map/favourite",
});

export const useGetCards = request({
  method: "GET",
  path: "payme/cards",
});

export const useGetPaymeHistory = request({
  method: "GET",
  path: "payme/history",
});

export const useAddCard = request(
  {
    method: "POST",
    path: "payme/card",
  },
  {
    onSuccess: () => useGetCards.getState().execute({ force: true }),
  }
);
export const useDeleteCard = request(
  {
    method: "DELETE",
    path: "payme/card",
  },
  {
    onSuccess: () => useGetCards.getState().execute({ force: true }),
  }
);

export const useGetTransactions = request({
  method: "GET",
  path: "transactions",
});

export const useGetCpoTransactions = request({
  method: "GET",
  path: "cpo/transactions",
});

export const useGetSingleTransaction = request({
  method: "GET",
  path: "transactions/79",
});

export const useTransactionsCPO = request({
  method: "GET",
  path: "transactions/cpo",
});

export const useTopUpPayme = request(
  {
    method: "POST",
    path: "payme/top-up",
  },
  {
    onSuccess: () => {
      useGetTransactions.getState().execute({ force: true });
      useGetMe.getState().execute({ force: true });
    },
  }
);

export const useToggleFavorite = request({
  method: "POST",
  path: "favourite/toggle",
});

export const useUserTransactions = request({
  method: "GET",
  path: "user_data/transactions",
});

export const useUserFeedback = request({
  method: "GET",
  path: "user_data/feedbacks",
});

export const useUserPackage = request({
  method: "GET",
  path: "user_data/packages",
});
export const useUserPayments = requestList({
  method: "GET",
  path: "user_data/payments",
});
export const useUserCharging = requestList({
  method: "GET",
  path: "user_data/charging",
});

export const useUsers = request({
  method: "GET",
  path: "user_data",
});

export const useSingleUser = request({
  method: "GET",
  path: "user_data/s",
});

export const useBlockUser = request({
  method: "PUT",
  path: "user_data/block",
});

export const useDeleteUserData = request(
  {
    method: "DELETE",
    path: "user_data",
  },
  {
    onSuccess: () => {
      useUsers.getState().execute({ force: true });
    },
  }
);

export const useMyCouponsList = request({
  method: "GET",
  path: "coupon/my",
});

export const useMyPackageList = request({
  method: "GET",
  path: "package/my",
});

export const useCouponsList = request({
  method: "GET",
  path: "coupon",
});

export const useCouponValidate = request({
  method: "POST",
  path: "coupon/validate",
});

export const useConnectorById = request({
  method: "GET",
  path: "map/connector",
});

export const useConnectorBelongings = request({
  method: "GET",
  path: "map/connector/belongings",
});

export const usePackagesList = request({
  method: "GET",
  path: "package/market-old",
});

export const usePackageCPO = request({
  method: "GET",
  path: "package/cpo",
});

export const useBuyPackage = request({
  method: "POST",
  path: "package/buy",
});

export const useDiscounts = request({
  method: "GET",
  path: "discount",
});

export const useDiscountsUpcoming = request({
  method: "GET",
  path: "discount/upcoming",
});
export const useAddDiscount = request({
  method: "POST",
  path: "discount",
});

export const useDiscountCPO = request({
  method: "GET",
  path: "discount/cpo",
});

export const useStartCharging = request({
  method: "POST",
  path: "ev/start-charging",
});

export const useStopCharging = request({
  method: "POST",
  path: "ev/stop-charging",
});

export const useSendNotification = request({
  method: "POST",
  path: "notification/send",
});

export const useGetNotifications = request({
  method: "GET",
  path: "notification",
});

export const useMyNotifications = request({
  method: "GET",
  path: "notification/my",
});

export const useDirectPartnerRequestChart = request({
  method: "GET",
  path: "direct-partner-request/chart",
});

export const useGetInstructions = request({
  method: "GET",
  path: "instruction",
});

export const useAddInstruction = request(
  {
    method: "POST",
    path: "instruction",
  },
  {
    onSuccess: () => {
      useGetInstructions.getState().execute({ force: true });
    },
  }
);
