import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { Button, Card, Col, Form, Image, Input, Row } from "antd";
import UploadGroupImage from "../../components/uploadImage/uploadGroupImage";
import { useAddInstruction, useGetInstructions } from "../../zustand/store";
import { BASE_URL } from "../../constants/link";

function InstructionPage() {
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);

  const addInstruction = useAddInstruction();

  const instruction = useGetInstructions();

  useEffect(() => {
    instruction.execute();
  }, []);

  return (
    <PageContainer title={"Instruction"}>
      <Form
        onFinish={(e) => {
          const data = {
            ...e,
            gifs: fileList?.map(({ base64, file }) => ({ base64 })),
          };
          addInstruction.execute({ data });
        }}
      >
        <Form.Item>
          <UploadGroupImage fileList={fileList} setFileList={setFileList} />
        </Form.Item>
        <Form.Item name={"name"} label="name">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

      <Row gutter={[10, 10]}>
        {instruction.data?.map(({ name, gifs }) => (
          <Col span={24}>
            <Card size="small" title={name}>
              <div style={{ display: "flex", gap: "10px" }}>
                {gifs.map((gif) => (
                  <img
                    style={{
                      height: "200px",
                      width: "auto",
                      display: "block",
                    }}
                    src={`${BASE_URL}/file/${gif}`}
                    alt="Gif"
                  />
                ))}
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </PageContainer>
  );
}

export default InstructionPage;
