import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LangText } from "../langManagement";
// import { PrivateComp } from "../roleBasedAccesses";
import {
  AimOutlined,
  BranchesOutlined,
  DashboardOutlined,
  GlobalOutlined,
  MoneyCollectOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useSidebar } from "../../zustand/store";
import { NavListWrapper, Wrapper } from "./styles";
// import { ADVERTISER, DRIVER, ORDER_TAKER, PIZZA_MAKER } from "../../constants/roles";

function Sidebar() {
  const navList = [
    {
      name: "Me",
      path: "/me",
      icon: <UserOutlined />,
    },
    {
      name: "auth",
      path: "/auth",
      icon: <UserOutlined />,
    },
    {
      name: "main",
      path: "/",
      icon: <DashboardOutlined />,
    },
    {
      name: "CPO",
      path: "/cpo",
      icon: <UserOutlined />,
    },
    {
      name: "plug",
      path: "/plug",
      icon: <BranchesOutlined />,
    },
    {
      name: "nearby",
      path: "/nearby",
      icon: <AimOutlined />,
    },
    {
      name: "Map",
      path: "/map",
      icon: <GlobalOutlined />,
    },
    {
      name: "payme",
      path: "/payme",
      icon: <MoneyCollectOutlined />,
    },
    {
      name: "users",
      path: "/users",
      icon: <UserSwitchOutlined />,
    },
    {
      name: "packages",
      path: "/packages",
      // icon: < />,
    },
    {
      name: "discounts",
      path: "/discounts",
      // icon: < />,
    },
    {
      name: "Charging Point",
      path: "/cp",
      // icon: < />,
    },
    {
      name: "Coupons",
      path: "/coupons",
      // icon: < />,
    },
    {
      name: "Notifications",
      path: "/notifications",
      // icon: < />,
    },
    {
      name: "Transactions",
      path: "/transactions",
      // icon: < />,
    },
  ];

  const sidebar = useSidebar();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <NavListWrapper>
        <Menu
          theme="dark"
          selectedKeys={[
            navList.find(
              ({ path }) => path === `/${location.pathname.split("/")[1]}`
            )?.name,
          ]}
          onClick={(e) => {
            console.log(e);
            const path = navList.find(({ name }) => name === e.key)?.path;
            navigate(path);
          }}
          items={navList.map(({ name, path, icon }) => ({
            label: <LangText id={name} />,
            key: name,
            icon,
            path,
          }))}
        />
        {/* {navList.map(({ name, path, p, and, or }, i) => (
          //   <PrivateComp key={i} p={p} and={and} or={or}>
          <NavItem
            key={i}
            to={path}
            active={
              path === "/"
                ? location.pathname === path
                : location.pathname.startsWith(path)
                ? "true"
                : undefined
            }
            onClick={() => sidebar.close()}
          >
            {name}
          </NavItem>
          //   </PrivateComp>
        ))} */}
      </NavListWrapper>
    </Wrapper>
  );
}

export default Sidebar;
