import React, { useState } from "react";
import { AddImgBox, ImageWrapper } from "./styles";
import { CloudUploadOutlined } from "@ant-design/icons";
import { getBase64 } from "../../../utils/base64";
import { Button, Col, Image, Row } from "antd";
import { LangText } from "../../langManagement";
import { BASE_URL } from "../../../constants/link";

function UploadSingleImage({ file, setFile }) {
  return (
    <div>
      {file.base64 || file?.id ? (
        <div 
        style={{ 
          maxWidth: "100px", 
          width: "fit-content" }}
        >
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <ImageWrapper>
                <Image
                  src={file?.base64 || `${BASE_URL}/file/${file?.id}`}
                  alt=""
                />
              </ImageWrapper>
            </Col>
            <Col span={24}>
              <Button
                block
                danger
                onClick={() => {
                  setFile({});
                }}
              >
                <LangText id="delete" />
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <AddImgBox>
          <CloudUploadOutlined height={100} style={{ fontSize: "48px" }} />
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              getBase64(file).then((base64) => {
                setFile({ file: null, base64 });
              });
            }}
          />
        </AddImgBox>
      )}
    </div>
  );
}

export default UploadSingleImage;
