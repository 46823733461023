export const displayAddress = ({ lat, lng, address }) => {
  if (lat && lng && address) {
    return (
      <>
        <a href={`https://www.google.com/maps?q=${lat},${lng}`} target="_blank">
          {address}
        </a>
      </>
    );
  } else if (lat && lng) {
    return (
      <>
        <a href={`https://www.google.com/maps?q=${lat},${lng}`} target="_blank">
          Manzil
        </a>
      </>
    );
  } else if (address) {
    return <>{address}</>;
  }
  return "";
};
