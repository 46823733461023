import { Col, Row } from "antd";
import React, { useState } from "react";
import { NewSocket, SocketCard } from "./styles";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import socketIcon from "../../../assets/socket-icon.png";
import socketImg from "../../../assets/socket.png";
import NewConnectorModal from "../../../components/modal/newConnector";
import { LangText } from "../../../components/langManagement";
import { BASE_URL } from "../../../constants/link";
import { thousandSeparator } from "../../../utils/numberManager";
function Socket({ connectorList, setConnectorList }) {
  const [connectorModal, setConnectorModal] = useState({ open: false });

  return (
    <>
      <Row gutter={[16, 16]}>
        {connectorList?.map(({ idTag, capacity, price, type, plug }, index) => (
          <Col>
            <SocketCard>
              <div className="title">
                <div className="text">
                  {plug?.name} /{" "}
                  {<LangText id={{ AC: "slow(AC)", DC: "fast(DC)" }[type]} />}
                </div>
                <div className="close-btn">
                  <CloseCircleFilled
                    style={{ color: "#292D3259" }}
                    onClick={() => {
                      setConnectorList((prev) =>
                        prev.filter((v, i) => i !== index)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="capacity">
                {capacity}W / {thousandSeparator(price)} <LangText id="soum" />{" "}
              </div>
              <div className="icon">
                <img src={`${BASE_URL}/file/${plug?.icon}`} alt="" />
              </div>
              <div className="socket-img">
                <img src={`${BASE_URL}/file/${plug?.image}`} alt="" />
              </div>
            </SocketCard>
          </Col>
        ))}

        <Col>
          <NewSocket>
            <div
              onClick={() => {
                setConnectorModal({ open: true });
              }}
            >
              <PlusOutlined />
            </div>
          </NewSocket>
        </Col>
      </Row>
      <NewConnectorModal
        {...connectorModal}
        onCancel={() => setConnectorModal({ open: false })}
        onSuccess={(v) => {
          setConnectorList((prev) => [...prev, v]);
          setConnectorModal({ open: false });
        }}
      />
    </>
  );
}

export default Socket;
