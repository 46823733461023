import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const NavbarPart = styled.div`
  /* background-color: #07BC84; */
  border-bottom: 1px solid black;
  background-color: #001628;
`;

export const PagePart = styled.div`
  width: 100%;
  height: calc(100vh - ${({ navbarHeight }) => navbarHeight}px);
  height: 100vh;
  display: flex;
  /* background-color: #fafafa; */
  /* background-color: rgba(0, 0, 0, 0.05); */
  background-color: #F4F4F4;
`;

export const SidebarPart = styled.div`
  width: 300px;
  overflow: auto;
  background-color: #001628;
  /* background-color: white; */
  @media only screen and (max-width: 850px) {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2;
    transition-duration: 0.5s;
    right: ${({ open }) => (open ? 0 : 100)}%;
  }
`;

export const ContentPart = styled.div`
  width: calc(100% - ${({ sidebarWidth }) => sidebarWidth}px);
  height: 100%;
  overflow: auto;
  /* background-color: lightblue; */
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`;
