export const validateUzPhoneNumber = (rule, value, callback) => {
  const phoneNumberRegex = /^[0-9]{9}$/; // Adjust the regex based on your validation criteria

  if (value && !phoneNumberRegex.test(value)) {
    callback("Please enter a valid phone number");
  } else {
    callback();
  }
};

export const validateEmail = (rule, value, callback) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for basic email validation

  if (value && !emailRegex.test(value)) {
    callback("Please enter a valid email address");
  } else {
    callback();
  }
};
