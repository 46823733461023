import { Input, Modal } from "antd";
import React, { useState } from "react";
import { LangText } from "../../langManagement";
import { useDeleteCPO } from "../../../zustand/store";

function DeleteCPOModal({ open, data, onCancel }) {
  const deleteCPO = useDeleteCPO();
  const [input, setInput] = useState("");

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      title={<LangText id="delete" />}
      okText={<LangText id="delete" />}
      okButtonProps={{ disabled: input !== data?.name }}
      onOk={() => {
        deleteCPO.execute({
          params: { id: data?.id },
          onSuccess: () => {
            onCancel();
          },
        });
      }}
    >
      {data?.name}
      <Input
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
      />
    </Modal>
  );
}

export default DeleteCPOModal;
