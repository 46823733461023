import { Avatar, Col, Row, Table, Tag, Typography } from "antd";
import React, { useEffect } from "react";
import { BASE_URL } from "../../constants/link";
import { useCPOListServerMigration } from "../../zustand/store";

const Station = ({ data }) => {
  const list = data.charging_stations;
  return (
    <>
      <Table
        bordered
        pagination={false}
        columns={[
          "id",
          "serial_number",
          "capacity_of_evc",
          //   "images",
          "idle_charger_fee",
          "electricity_fee",
          "connectors",
          //   "active",
          //   "stationId",
          //   "sessionStartedAt",
          //   "sessionId",
          //   "password",
          //   "status",
          //   "lastHeartbeatAt",
          //   "stationBootData",
          //   "idTag",
          //   "hide",
        ].map((c) => ({
          key: c,
          dataIndex: c,
          title: c,
          ...(c === "connectors"
            ? { width: "200px" }
            : c === "id"
            ? { width: "10px" }
            : {}),
        }))}
        dataSource={
          list?.map((v) => ({
            ...v,
            connectors: (
              <div
                style={{
                  width: "250px",
                  display: "flex",
                  //   gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {v.connectors?.map((v) => (
                  <div>
                    {v.plug?.image && (
                      <Avatar
                        size="small"
                        src={`${BASE_URL}/file/${v.plug?.image}`}
                      />
                    )}{" "}
                    {/* {v?.connectorId} */}
                    <small>{v?.plug?.name}</small>
                  </div>
                ))}
              </div>
            ),
          })) || []
        }
      />
    </>
  );
};

const CP = ({ data }) => {
  const list = data.charging_points;
  return (
    <>
      <Table
        bordered
        pagination={false}
        columns={[
          "id",
          "name",
          "address",
          //   "addressText",
          //   "geoLocation",
          "parkingType",
          "workHours",
          "nearby",
          //   "parkingFeeWhileCharging",
          //   "withUmbrella",
          //   "verified",
          //   "active",
          //   "hide",
        ].map((c) => ({
          key: c,
          dataIndex: c,
          title: c,
          ...(c === "id" ? { width: "10px" } : {}),
        }))}
        dataSource={
          list?.map((v) => ({
            ...v,
            address: v.addressText?.uz,
            geoLocation: <>{JSON.stringify(v.geoLocation || {})}</>,
            nearby: (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {v.nearby_locations?.map(({ name, nearby }) => (
                  <div>
                    {nearby.image && (
                      <Avatar
                        size={"small"}
                        src={`${BASE_URL}/file/${nearby?.image}`}
                      />
                    )}{" "}
                    {name}
                  </div>
                ))}
              </div>
            ),
            workHours: (
              <>
                {
                  // false &&
                  v.workHours.from === "0:0" ||
                  v.workHours.from === "00:00" ||
                  v.workHours.from === "23:59" ? (
                    <div>24/7</div>
                  ) : (
                    <div>
                      {v.workHours.from} - {v.workHours.to}
                    </div>
                  )
                }
              </>
            ),
          })) || []
        }
        expandable={{
          defaultExpandAllRows: true,
          expandedRowRender: (data) => {
            return <Station data={data} />;
          },
        }}
      />
    </>
  );
};

function ServerMigration() {
  const serverMigration = useCPOListServerMigration();

  useEffect(() => {
    serverMigration.execute();
  }, []);

  return (
    <>
      {/* <PageContainer title={"ServerMigration"}> */}
      {serverMigration.data?.length > 0 && (
        <Table
          bordered
          pagination={false}
          size="small"
          columns={[
            "id",
            "name",
            //   "tradeMark",
            //   "logo",
            //   "location",
            //   "color",
            "contact_beon",
            "contact",
            //   "verified",
            //   "rejected",
            "partnership",
            //   "active",
            //   "rate",
            //   "rateCount",
            //   "projectOwner",
            //   "hide",
          ].map((c) => ({
            key: c,
            dataIndex: c,
            title: c,
            ...(c === "id" ? { width: "10px" } : {}),
          }))}
          dataSource={
            serverMigration.data?.map((v) => ({
              ...v,
              // location: <>{JSON.stringify(v.location)}</>,
              contact: (
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>{v?.contact?.website}</div>
                  <div>{v?.contact?.email}</div>
                  <div>{v?.contact?.phoneNumber}</div>
                </div>
              ),
              contact_beon: (
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>{v?.contact_beon?.website}</div>
                  <div>{v?.contact_beon?.email}</div>
                  <div>{v?.contact_beon?.phoneNumber}</div>
                </div>
              ),
              partnership: (
                <div style={{ display: "flex", gap: "10px" }}>
                  {v?.partnership?.status && (
                    <div>
                      <Tag>{v?.partnership?.status}</Tag>
                    </div>
                  )}
                  {v?.partnership?.app?.appStore && (
                    <div>
                      <Typography.Link href={v?.partnership?.app?.appStore}>
                        AppStore
                      </Typography.Link>
                    </div>
                  )}
                  {v?.partnership?.app?.playMarket && (
                    <div>
                      <Typography.Link href={v?.partnership?.app?.playMarket}>
                        PlayMarket
                      </Typography.Link>
                    </div>
                  )}
                </div>
              ),
              name: (
                <Row gutter={[10, 10]} align={"middle"}>
                  {v?.logo && (
                    <Col>
                      <Avatar src={`${BASE_URL}/file/${v?.logo}`} />
                    </Col>
                  )}
                  <Col>
                    <div>
                      <>{v.tradeMark}</>
                    </div>
                  </Col>
                </Row>
              ),
            })) || []
          }
          expandable={{
            defaultExpandAllRows: true,
            // expandIcon: () => false,
            expandedRowRender: (data) => {
              return <CP data={data} />;
            },
          }}
        />
      )}
      {/* </PageContainer> */}
    </>
  );
}

export default ServerMigration;
