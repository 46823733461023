import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Card, Col, Image, Modal, Row } from "antd";
import React, { useState } from "react";
import { LangText } from "../../../components/langManagement";
import { BASE_URL } from "../../../constants/link";
import { useDeletePlug } from "../../../zustand/store";

const DeleteModal = ({ open, id, onCancel }) => {
  const deletePlug = useDeletePlug();
  return (
    <Modal
      onCancel={onCancel}
      open={open}
      title={<LangText id="delete" />}
      onOk={() => {
        deletePlug.execute({ params: { id } });
      }}
      okText={<LangText id="delete" />}
    >
      <LangText id="reallyWantToDelete" />
    </Modal>
  );
};

function SinglePlug({
  active,
  createdAt,
  icon,
  id,
  image,
  name,
  updatedAt,
  setForm,
}) {
  const [deleteModal, setDeleteModal] = useState({ open: false });

  return (
    <>
      <Card
        size="small"
        actions={[
          <EditOutlined
            onClick={() => {
              setForm({
                open: true,
                edit: true,
                data: {
                  active,
                  createdAt,
                  icon,
                  id,
                  image,
                  name,
                  updatedAt,
                },
              });
            }}
          />,
          <DeleteOutlined
            onClick={() => {
              setDeleteModal({ open: true, id });
            }}
          />,
        ]}
      >
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                marginBottom: "5px",
              }}
            >
              <LangText id="image" />
            </div>
            <Image src={`${BASE_URL}/file/${image}`} />
          </Col>
          <Col span={12}>
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                marginBottom: "5px",
              }}
            >
              <LangText id="icon" />
            </div>
            <Image src={`${BASE_URL}/file/${icon}`} />
          </Col>
          <Col span={24}>
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                marginBottom: "5px",
              }}
            >
              {name}
            </div>
          </Col>
        </Row>
      </Card>
      <DeleteModal
        open={deleteModal.open}
        id={deleteModal.id}
        onCancel={() => setDeleteModal({ open: false })}
      />
    </>
  );
}

export default SinglePlug;
