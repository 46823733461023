import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import {
  useBlockUser,
  useSingleUser,
  useUserCharging,
  useUserFeedback,
  useUserPackage,
  useUserPayments,
  useUserTransactions,
} from "../../zustand/store";
import { useParams } from "react-router-dom";
import { Button, Tabs } from "antd";
import Payments from "./payments";
import Charging from "./charging";

function SingleUserPage() {
  const singleUser = useSingleUser();
  const blockUser = useBlockUser();

  const transactions = useUserTransactions();
  const feedbacks = useUserFeedback();
  const packages = useUserPackage();

  const payments = useUserPayments();
  const charging = useUserCharging();

  const { id } = useParams();
  const userId = id;

  useEffect(() => {
    singleUser.execute({ params: { id }, force: true });
    transactions.execute({ params: { userId }, force: true });
    feedbacks.execute({ params: { userId }, force: true });
    payments.execute({ params: { userId }, force: true });
    charging.execute({ params: { userId }, force: true });
    packages.execute({ params: { userId, active: true }, force: true });
  }, []);
  return (
    <PageContainer
      title={"SingleUser"}
      rightPart={
        <>
          <Button
            onClick={() => {
              blockUser.execute({
                params: { id },
                onSuccess: () => {
                  singleUser.execute({ params: { id }, force: true });
                },
              });
            }}
          >
            Block
          </Button>
        </>
      }
    >
      SingleUserPage
      <Tabs
        items={[
          {
            key: "1",
            label: "Payments",
            children: <Payments />,
          },
          {
            key: "2",
            label: "Charging",
            children: <Charging />,
          },
        ]}
      />
    </PageContainer>
  );
}

export default SingleUserPage;
