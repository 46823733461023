import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import CPOPage from "../cpoPage";
import CreateStationPage from "../formChargingStation";
import FormCPOPage from "../formCPOPage";
import FormChargingPoint from "../formChargingPoint";
import NearbyPage from "../nearbyPage";
import PlugPage from "../plugPage";
import SingleCPOPage from "../singleCPOPage";
import { ContentPart, NavbarPart, PagePart, SidebarPart } from "./styles";
import SingleChargingPoint from "../singleChargingPoint";
import FormChargingStation from "../formChargingStation";
import SingleStationPage from "../singleStationPage";
import MapStation from "../mapStation";
import AuthPage from "../authPage";
import PaymePage from "../paymePage";
import UsersPage from "../usersPage";
import SingleUserPage from "../singleUserPage";
import PackagesPage from "../packagesPage";
import MePage from "../mePage";
import DiscountPage from "../discountPage";
import ChargingPointPage from "../cpPage";
import SingleCPPage from "../singleCpPage";
import CouponsPage from "../couponsPage";
import NotificationPage from "../notificationPage";
import TransactionsPage from "../transactions";
import InstructionPage from "../instructionPage";
import OperatorStationsPage from "../operatorStationsPage";
import AnalyzeMeterValue from "../analyzeMeterValues";
import ServerMigration from "../serverMigration";

function MainPage() {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [sidebarWidth, setSidebarWidth] = useState(0);

  //   const sidebar = useSidebar();
  useEffect(() => {
    setNavbarHeight(document.querySelector(".navbar-part")?.clientHeight || 0);
    setSidebarWidth(document.querySelector(".sidebar-part")?.clientWidth || 0);
  }, []);

  return (
    <>
      <Routes>
        <Route path="/server-migration" element={<ServerMigration />} />
        <Route
          path="/"
          element={
            <>
              <PagePart navbarHeight={navbarHeight}>
                <SidebarPart
                  // open={sidebar.status}
                  className="sidebar-part"
                >
                  <Sidebar />
                </SidebarPart>
                <ContentPart sidebarWidth={sidebarWidth}>
                  <NavbarPart className="navbar-part">
                    <Navbar />
                  </NavbarPart>
                  <Outlet />
                </ContentPart>
              </PagePart>
            </>
          }
        >
          <Route
            path="/"
            element={
              <>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Accusantium optio harum esse architecto hic laboriosam quia?
                Amet non temporibus corrupti aperiam fugiat assumenda nobis
                cupiditate architecto consequuntur, unde ipsam. Distinctio?
              </>
            }
          />
          <Route path="/me" element={<MePage />} />
          <Route path="/cpo" element={<CPOPage />} />
          <Route path="/cpo/create" element={<FormCPOPage />} />
          <Route path="/cpo/:cpoId/edit" element={<FormCPOPage />} />
          <Route path="/cpo/:cpoId" element={<SingleCPOPage />} />
          <Route
            path="/cpo/:cpoId/station/create"
            element={<CreateStationPage />}
          />
          <Route
            path="/cpo/:cpoId/charging-point/create"
            element={<FormChargingPoint />}
          />
          <Route
            path="/cpo/:cpoId/charging-point/:cpId/edit"
            element={<FormChargingPoint />}
          />
          <Route
            path="/cpo/:cpoId/charging-point/:cpId"
            element={<SingleChargingPoint />}
          />
          <Route
            path="/cpo/:cpoId/charging-point/:cpId/station/create"
            element={<FormChargingStation />}
          />
          <Route
            path="/cpo/:cpoId/charging-point/:cpId/station/:sId/edit"
            element={<FormChargingStation />}
          />
          <Route
            path="/cpo/:cpoId/charging-point/:cpId/station/:sId"
            element={<SingleStationPage />}
          />

          <Route path="/plug" element={<PlugPage />} />
          <Route path="/nearby" element={<NearbyPage />} />
          <Route path="/map" element={<MapStation />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/payme" element={<PaymePage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/users/:id" element={<SingleUserPage />} />
          <Route path="/packages" element={<PackagesPage />} />
          <Route path="/discounts" element={<DiscountPage />} />
          <Route path="/cp" element={<ChargingPointPage />} />
          <Route path="/cp/:id" element={<SingleCPPage />} />
          <Route path="/coupons" element={<CouponsPage />} />
          <Route path="/notifications" element={<NotificationPage />} />
          <Route path="/transactions" element={<TransactionsPage />} />
          <Route path="/instruction" element={<InstructionPage />} />
          <Route path="/operator" element={<OperatorStationsPage />} />
          <Route path="/analyze-meter-value" element={<AnalyzeMeterValue />} />
        </Route>
      </Routes>
    </>
  );
}

export default MainPage;
