import {
  Button,
  Col,
  ColorPicker,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { LangText } from "../../components/langManagement";
import UploadSingleImage from "../../components/uploadImage/uploadSingleImage";
import {
  CloseOutlined,
  GlobalOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import MapBox from "../../components/mapBox";
import {
  useAddCPO,
  useDeleteCPO,
  useDeleteFile,
  useEditCPO,
  useGetSingleCPO,
} from "../../zustand/store";
import {
  validateEmail,
  validatePhoneNumber,
  validateUzPhoneNumber,
} from "../../utils/validation.helper";
import { PageContainer } from "../../components/container";
import { useNavigate, useParams } from "react-router-dom";

function FormCPOPage({ open, onClose, data }) {
  const [file, setFile] = useState({});
  const [color, setColor] = useState("");
  const [mapOpen, setMapOpen] = useState(false);
  const [markerCoords, setMarkerCoords] = useState({});
  const [form] = Form.useForm();

  const { cpoId } = useParams();
  const edit = Boolean(cpoId);

  const addCPO = useAddCPO();
  const editCPO = useEditCPO();
  const singleCPO = useGetSingleCPO();

  const navigate = useNavigate();

  useEffect(() => {
    if (edit) {
      console.log(data);
      setColor(data?.color);
      setFile({ id: data?.logo });
      if (data?.location?.lat && data?.location?.lng) {
        setMarkerCoords({
          lat: data?.location?.lat || null,
          lng: data?.location?.lng || null,
        });
        setMapOpen(true);
      } else {
        setMapOpen(false);
        setMarkerCoords({});
      }
      form.setFieldsValue({
        name: data?.name || "",
        address: data?.location?.address || "",

        phoneNumber: data?.contact?.phoneNumber.replace("+998", "") || "",
        email: data?.contact?.email || "",
        website: data?.contact?.website || "",
      });
    }
  }, []);

  useEffect(() => {
    singleCPO.execute({
      params: { id: cpoId },
      force: true,
      onSuccess: (res) => {
        const data = res.data;
        console.log(data);
        setColor(data?.color);
        setFile({ id: data?.logo });
        if (data?.location?.lat && data?.location?.lng) {
          setMarkerCoords({
            lat: data?.location?.lat || null,
            lng: data?.location?.lng || null,
          });
          setMapOpen(true);
        } else {
          setMapOpen(false);
          setMarkerCoords({});
        }
        form.setFieldsValue({
          name: data?.name || "",
          address: data?.location?.address || "",

          phoneNumber: data?.contact?.phoneNumber.replace("+998", "") || "",
          email: data?.contact?.email || "",
          website: data?.contact?.website || "",
          phoneNumberBeon:
            data?.contact_beon?.phoneNumber.replace("+998", "") || "",
          emailBeon: data?.contact_beon?.email || "",
          websiteBeon: data?.contact_beon?.website || "",
        });
      },
    });
  }, [cpoId]);
  return (
    <PageContainer
      width={500}
      open={open}
      onClose={() => {
        setColor("#000");
        setFile({});
        setMarkerCoords({});
        form.resetFields();
        onClose();
      }}
      title={<LangText id="createNewCPO" />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(e) => {
          // console.log(e, color.toHexString());
          const reqData = {
            name: e.name,
            logo:
              file?.base64 || file?.id
                ? { base64: file.base64, id: file.id }
                : undefined,
            color:
              typeof color === "string"
                ? color
                : color?.toHexString() || "#000000",
            location: {
              lat: markerCoords?.lat,
              lng: markerCoords?.lng,
              address: e?.address,
            },
            contact_beon: {
              phoneNumber: "+998" + e.phoneNumberBeon,
              email: e.emailBeon || undefined,
              website: e.websiteBeon || undefined,
            },
            contact: {
              phoneNumber: "+998" + e.phoneNumber,
              email: e.email || undefined,
              website: e.website || undefined,
            },
          };
          (edit ? editCPO : addCPO).execute({
            params: { id: cpoId },
            data: reqData,
            onSuccess: () => {
              console.log("SUCCESS_ACTION_CPO");
              // onClose();
              // setColor("#000");
              // setFile({});
              // setMarkerCoords({});
              // form.resetFields();
              navigate("/cpo");
            },
          });
        }}
      >
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Form.Item
              label={<LangText id="name" />}
              name="name"
              required
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div>
              <Typography.Text strong>
                <LangText id="contactForBeon" />
              </Typography.Text>
            </div>
            <div>
              <Typography.Text type="secondary">
                <LangText id="contactOnlyVisibleForBeon" />
              </Typography.Text>
            </div>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LangText id="phoneNumber" />}
              name={"phoneNumberBeon"}
              required
              rules={[
                { required: true, message: "Please enter your phone number" },
                { validator: validateUzPhoneNumber },
              ]}
            >
              <Input addonBefore="+998" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LangText id="email" />}
              name="emailBeon"
              rules={[{ validator: validateEmail }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LangText id="website" />}
              rules={[{ type: "url" }, { type: "string", min: 6 }]}
              name={"websiteBeon"}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div>
              <Typography.Text strong>
                <LangText id="contactForUsers" />
              </Typography.Text>
            </div>
            <div>
              <Typography.Text type="secondary">
                <LangText id="contactOnlyVisibleForUsers" />
              </Typography.Text>
            </div>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LangText id="phoneNumber" />}
              name={"phoneNumber"}
              required
              rules={[
                { required: true, message: "Please enter your phone number" },
                { validator: validateUzPhoneNumber },
              ]}
            >
              <Input addonBefore="+998" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LangText id="email" />}
              name="email"
              rules={[{ validator: validateEmail }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LangText id="website" />}
              rules={[{ type: "url" }, { type: "string", min: 6 }]}
              name={"website"}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              name="address"
              label={LangText({ id: "address" })}
              rules={[{ required: true, message: "Please enter your address" }]}
              extra={
                markerCoords?.lat && markerCoords?.lng ? (
                  <>
                    <div title="Latitude">lat: {markerCoords?.lat}</div>{" "}
                    <div title="Longitude">lng: {markerCoords?.lng}</div>
                  </>
                ) : (
                  false
                )
              }
            >
              <Input />
            </Form.Item>
          </Col>
          {/* {mapOpen ? ( */}
          <Col span={24}>
            <Form.Item>
              <MapBox
                name={form.getFieldValue("name")}
                markerCoords={markerCoords}
                setMarkerCoords={setMarkerCoords}
              />
            </Form.Item>
          </Col>
          {/* ) : (
            <></>
          )} */}
          <Col span={24}>
            <Form.Item
              name={"typeOfPartnership"}
              label={<LangText id="typeOfPartnership" />}
              required
              rules={[{ required: true }]}
            >
              <Radio.Group
              // onChange={onChange} value={value}
              >
                <Radio value={"direct"}>
                  <LangText id="directPartner" />
                </Radio>
                <Radio value={"indirect"}>
                  <LangText id="indirectPartner" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col>
            <Form.Item label={<LangText id="logo" />}>
              <UploadSingleImage file={file} setFile={setFile} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={<LangText id="color" />}>
              <ColorPicker
                width={100}
                value={color}
                onChange={setColor}
                showText
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[10, 10]}>
            <Col>
              <Button
                onClick={() => {
                  navigate("/cpo");
                }}
              >
                <LangText id={"cancel"} />
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                <LangText id={edit ? "saveChanges" : "create"} />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </PageContainer>
  );
}

export default FormCPOPage;
