import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import {
  useBuyPackage,
  useConnectorBelongings,
  useCouponValidate,
  useMyCouponsList,
  useMyPackageList,
  usePackageCPO,
  usePackagesList,
} from "../../zustand/store";
import { Button, Card, Col, Row } from "antd";

function PackagesPage() {
  const packagesList = usePackagesList();
  const packageCPO = usePackageCPO();
  const buyPocket = useBuyPackage();
  const myCoupons = useMyCouponsList();
  const myPackage = useMyPackageList();
  const belongings = useConnectorBelongings();

  useEffect(() => {
    packagesList.execute({ force: true });
    belongings.execute({ force: true, params: { id: 113 } });
    packageCPO.execute({ params: { limit: 5 } });
    myCoupons.execute();
    myPackage.execute();
  }, []);

  return (
    <PageContainer title={"Packages"}>
      {packagesList.data?.list?.map(({ name, packages }) => (
        <Card size="small">
          <div>{name}</div>
          <Row gutter={[10, 10]}>
            {packages?.map(({ id, price }) => (
              <Col>
                <Card
                  size="small"
                  actions={[
                    <Button
                      onClick={() => {
                        buyPocket?.execute({
                          params: { id },
                        });
                      }}
                    >
                      Buy
                    </Button>,
                  ]}
                >
                  {price}
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      ))}
      Packages Lorem ipsum dolor sit, amet consectetur adipisicing elit.
      Molestiae ipsum vitae dicta aut repudiandae molestias animi. A quod
      dolores assumenda odit nobis, sunt autem sapiente ut dicta error nihil
      veritatis.
    </PageContainer>
  );
}

export default PackagesPage;
