import React, { useEffect, useState } from "react";

import { PageContainer } from "../../components/container";
import { Button, Card, Col, Form, Input, Row, Table } from "antd";
import {
  useDeleteUser,
  useGetFeedbackOption,
  useGetUserMe,
  useGetUsersForOTP,
  useGetreportOption,
  useVerifyOTP,
  useVerifyPN,
} from "../../zustand/store";
import dayjs from "dayjs";

function AuthPage() {
  const verify = useVerifyPN();
  const verifyOTP = useVerifyOTP();
  const users = useGetUsersForOTP();
  const feedback = useGetFeedbackOption();
  const report = useGetreportOption();
  const deleteUser = useDeleteUser();
  const me = useGetUserMe();

  const [myToken, setMyToken] = useState("");

  useEffect(() => {
    users.execute();
    const token = localStorage.getItem("my_token");
    setMyToken(token);

    feedback.execute();
    report.execute();
  }, []);

  return (
    <PageContainer title={<>Auth {myToken ? "ONLINE" : "OFFLINE"}</>}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Card>
            {JSON.stringify(verify.data || {})}
            <Form
              layout="vertical"
              onFinish={(e) => {
                const phoneNumber = e.phoneNumber;
                verify.execute({
                  data: { phoneNumber },
                });
              }}
            >
              <Form.Item name={"phoneNumber"} label={"phoneNumber"}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Submit</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            {verify?.data?.token}
            <Form
              layout="vertical"
              onFinish={({ code }) => {
                verifyOTP.execute({
                  data: { code },
                  headers: {
                    Authorization: `Bearer ${verify?.data?.token}`,
                  },
                  onSuccess: (res) => {
                    console.log();
                    const t = res?.data?.token;
                    localStorage.setItem("my_token", t);
                    setMyToken(t);
                  },
                });
              }}
            >
              <Form.Item name={"code"} label={"code"}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Submit</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col>
          <Card>
            <Button
              type="primary"
              loading={deleteUser.loading}
              onClick={() => {
                deleteUser.execute();
              }}
            >
              Delete
            </Button>
          </Card>
        </Col>
        <Col span={24}>
          <div>{JSON.stringify(me.data || {})}</div>
          <div>
            <Button
              onClick={() => {
                me.execute({ force: true });
              }}
            >
              Get Me
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <Table
            columns={["id", "fullname", "phoneNumber", "otp"].map((name) => ({
              key: name,
              dataIndex: name,
              title: name,
            }))}
            dataSource={
              users.data?.map((d) => ({
                ...d,
                otp: (
                  <>
                    <div>{d?.otp?.code}</div>
                    <div>{d?.otp?.phoneNumber}</div>
                    <div>{dayjs(d?.otp?.date).format("DD/MM/YYYY HH:mm")}</div>
                  </>
                ),
              })) || []
            }
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default AuthPage;
