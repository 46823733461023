import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import { useUsers } from "../../zustand/store";
import { Table } from "antd";
import { Link } from "react-router-dom";
import TablePagination from "../../components/tablePagination";

function UsersPage() {
  const users = useUsers();
  const data = users.data;
  const page = Number(data?.page) || 0;
  const limit = Number(data?.limit) || 0;
  const count = Number(data?.count) || 0;

  useEffect(() => {
    users.execute({ force: true });
  }, []);
  return (
    <PageContainer title={"Users"}>
      UsersPage
      <Table
        pagination={{
          current: page,
          onChange: (e) => {
            console.log(e);
            users?.execute({
              force: true,
              params: { page: e, limit },
            });
          },
          pageSize: limit,
          total: count,
        }}
        columns={["id", "phoneNumber"].map((d) => ({
          key: d,
          dataIndex: d,
          title: d,
        }))}
        dataSource={
          users?.data?.list.map((d) => ({
            ...d,
            id: (
              <>
                <Link to={`/users/${d?.id}`}>{d?.id}</Link>
              </>
            ),
          })) || []
        }
      />
    </PageContainer>
  );
}

export default UsersPage;
