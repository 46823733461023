import styled from "styled-components";

export const MainWrapper = styled.div`
  padding: 20px 24px;
`;

export const Wrapper = styled.div`
  background-color: white;
  padding: 32px 24px;
  border-radius: 12px;
`;



export const Title = styled.div`
  font-size: 32px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: "Futura";
  }
`;

export const BodyPart = styled.div``;
