import React, { useEffect } from "react";
import { WS_BASE_URL } from "../constants/link";
import { useGetCP_ById, useGetSingleCP } from "../zustand/store";
// import notification from "../assets/audio/new_order_notification.mp3";
// import { useGetCurrentDial, useGetOrders } from "../../../zustand/store";
// "https://dw.zobj.net/download/v1/bsy-si82CvdvofoRiil-ejOYGlqErEE1rw-tSiIRWy5fBEXeZfCpIX5yXZfCXaERRH750v83Y4Fj-MSuZW-if_zzSeLp1_u90FJQlS29zL3Envfr01AVIvq9IpUM/?a=&c=72&f=iphone_14_notification.mp3&special=1703530054-n%2Fb%2Bo%2FjxNRzQ3q5TzKKbbO4wFQgnjGkv%2FHSsooobq9w%3D"
// const audio = new Audio(notification);

// console.log(WS_BASE_URL);
function WS({ baker, orderTaker, connector, station, cpId }) {
  //   const orders = useGetBakerOrders();
  // const currentDial = useGetCurrentDial();
  //   const dial = useDial();

  const singleCP = useGetCP_ById();

  useEffect(() => {
    console.log("WS mount");

    const token = localStorage.getItem("my_token");

    const params = new URLSearchParams();
    params.append("token", token);
    if (station) {
      params.append("screen", "STATION");
      params.append("stationId", station);
    }

    if(connector){
      params.append("screen", "CONNECTOR");
      params.append("connectorId", connector);
    }

    console.log(params.toString());
    const query = params.toString();

    let socket = new WebSocket(`${WS_BASE_URL}?${query}`);

    let interval = null;

    socket.addEventListener("open", (event) => {
      console.log("WebSocket connection opened:", event);
      interval = setInterval(() => {
        console.log("HEARTBEAT");
        socket.send("HEARTBEAT");
      }, 45 * 1000);
    });

    socket.addEventListener("message", (event) => {
      console.log("Received message:", event.data);
      if (event.data === "NEW_ORDER" && baker) {
        // audio.play();
        // orders.execute({ force: true });
      } else if (event.data === "HEARTBEAT") {
      } else {
        // if (orderTaker) {
        try {
          const data = JSON.parse(event.data);
          const number = data.number;

          if (
            data.action === "CHARGING" ||
            data.action === "CHARGING_STOPPED"
          ) {
            //   dial.add(data);
            singleCP.execute({ params: { id: cpId }, force: true });
          } else if (data.action === "IDLE") {
            //   dial.remove(number);
          }
        } catch (err) {
          // console.log(err);
        }
        // }
      }
    });

    socket.addEventListener("error", (event) => {
      console.error("WebSocket error:", event);
    });

    socket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed:", event);
      clearInterval(interval);
      // socket = new WebSocket(`${WS_BASE_URL}/token=${token}`);
    });

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      console.log("WS unmount");
      socket.close();
    };
  }, []);
  return <></>;
}

export default WS;
