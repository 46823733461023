import React, { useState } from "react";
import { AddImgBox, ImageBox } from "./styles";
import { CloudUploadOutlined } from "@ant-design/icons";
import { getBase64 } from "../../../utils/base64";
import { Col, Row } from "antd";
import { BASE_URL } from "../../../constants/link";

function UploadGroupImage({ fileList, setFileList }) {
  // const [fileList, setFileList] = useState([]);
  return (
    <div>
      <br />
      <Row gutter={[10, 10]}>
        <Col>
          <AddImgBox>
            <CloudUploadOutlined height={100} style={{ fontSize: "48px" }} />
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={(e) => {
                const array = e.target.files;
                for (let index = 0; index < array.length; index++) {
                  const file = array[index];

                  getBase64(file).then((base64) => {
                    setFileList((prev) => [...prev, { file: null, base64 }]);
                  });
                }
              }}
            />
          </AddImgBox>
        </Col>
        {fileList.map(({ base64, id }) => (
          <Col>
            <ImageBox>
              <img src={id ? `${BASE_URL}/file/${id}` : base64} alt="" />
            </ImageBox>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default UploadGroupImage;
