import { Pagination } from "antd";
import React from "react";

function TablePagination({ page, limit, count, state }) {
  return (
    <div style={{ marginTop: "10px" }}>
      <Pagination
        current={page}
        onChange={(e) => {
          console.log(e);
          state?.execute({
            force: true,
            params: { page: e, limit },
          });
        }}
        pageSize={limit}
        total={count}
      />
    </div>
  );
}

export default TablePagination;
