import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import { useGetCPO, useGetSingleCPO } from "../../zustand/store";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import { LangText } from "../../components/langManagement";
import { PlusOutlined } from "@ant-design/icons";

function StationPage() {
  const { cpoId } = useParams();

  const singleCPO = useGetSingleCPO();
  const navigate = useNavigate();

  useEffect(() => {
    singleCPO.execute({ force: true });
  }, []);

  return (
    <PageContainer
      title="station"
      rightPart={
        <>
          <Button
            type="primary"
            onClick={() => {
              navigate(`/cpo/${cpoId}/station/create`);
            }}
          >
            <PlusOutlined /> <LangText id="createStation" />
          </Button>
        </>
      }
    ></PageContainer>
  );
}

export default StationPage;
