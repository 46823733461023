import { Button, Dropdown, Image, Table } from "antd";
import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
// import CPOForm from "./cpoForm";
import { useNavigate } from "react-router-dom";
import DeleteCPOModal from "../../components/modal/deleteCPOModal";
import TablePagination from "../../components/tablePagination";
import { BASE_URL } from "../../constants/link";
import { displayAddress } from "../../utils/address.helper";
import { useDirectPartnerRequestChart, useGetCPO } from "../../zustand/store";
import FilterCPO from "./filter";

const columns = [
  //   "id",
  "name",
  "address",
  "logo",
  "color",
  //   "color",
  "contact_beon",
  "contact",
  //   "verified",
  //   "active",
  // "createdAt",
  "typeOfPartnership",
  "action",
];

function CPOPage() {
  const [form, setForm] = useState({ open: false });
  const [deleteModal, setDeleteModal] = useState({ open: false, data: null });
  const cpo = useGetCPO();
  const data = cpo.data;
  const page = Number(data?.page) || 0;
  const limit = Number(data?.limit) || 0;
  const count = Number(data?.count) || 0;
  const navigate = useNavigate();
  const chart = useDirectPartnerRequestChart();

  useEffect(() => {
    chart.execute();
    // cpo.execute({ params: { searchName: "+998" } });
  }, []);

  return (
    <>
      <PageContainer title={<LangText id="cpo" />}>
        <FilterCPO />
        <br />
        <Table
          bordered
          size="small"
          loading={cpo.loading}
          columns={columns.map((name) => ({
            title: name,
            dataIndex: name,
            key: name,
          }))}
          pagination={false}
          dataSource={cpo.data?.list?.map((v) => {
            const {
              active,
              color,
              contact,
              contact_beon,
              createdAt,
              id,
              location,
              logo,
              name,
              updatedAt,
              verified,
            } = v;
            return {
              name,
              address: (
                <>
                  {displayAddress({
                    address: location?.address,
                    lat: location.lat,
                    lng: location.lng,
                  })}
                </>
              ),
              logo: (
                <>
                  {logo ? (
                    <Image
                      style={{ margin: "auto" }}
                      width={50}
                      src={`${BASE_URL}/file/${logo}`}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ),
              color: (
                <>
                  <div
                    style={{
                      backgroundColor: color,
                      padding: "10px",
                      width: "fit-content",
                      margin: "auto",
                    }}
                  />
                </>
              ),
              contact: (
                <>
                  <div>{contact?.phoneNumber}</div>
                  <div>{contact?.email}</div>
                  <div>{contact?.website}</div>
                </>
              ),
              contact_beon: (
                <>
                  <div>{contact_beon?.phoneNumber}</div>
                  <div>{contact_beon?.email}</div>
                  <div>{contact_beon?.website}</div>
                </>
              ),
              // createdAt: <>{dayjs(createdAt).format("DD/MM/YY hh:mm")}</>,
              typeOfPartnership: (
                <>
                  <LangText id="indirect" />
                </>
              ),
              action: (
                <div>
                  <Dropdown
                    arrow={true}
                    menu={{
                      items: [
                        {
                          key: "view",
                          label: (
                            <div
                              onClick={() => {
                                navigate(`/cpo/${id}`);
                              }}
                            >
                              <LangText id={"view"} />
                            </div>
                          ),
                        },
                        {
                          key: "edit",
                          label: (
                            <div
                              onClick={() => {
                                navigate(`/cpo/${id}/edit`);
                              }}
                            >
                              <LangText id={"edit"} />
                            </div>
                          ),
                        },
                        {
                          key: "delete",
                          label: (
                            <div
                              onClick={() => {
                                setDeleteModal({ open: true, data: v });
                              }}
                            >
                              <LangText id={"delete"} />
                            </div>
                          ),
                        },
                      ],
                    }}
                  >
                    <Button>action</Button>
                  </Dropdown>
                </div>
              ),
            };
          })}
        />
        <TablePagination page={page} limit={limit} count={count} state={cpo} />

        <DeleteCPOModal
          {...deleteModal}
          onCancel={() => setDeleteModal({ open: false })}
        />
      </PageContainer>
    </>
  );
}

export default CPOPage;
