import { Avatar, Button, Card, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../constants/link";
import MapBox from "../../mapBox";
import { setOptions } from "leaflet";
import { LangText } from "../../langManagement";
import { CloseCircleOutlined } from "@ant-design/icons";

function NearbyLocationForm({ open, nearby, edit, onCancel, onSuccess }) {
  const [markerCoords, setMarkerCoords] = useState({});
  const [mapOpen, setMapOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.resetFields();
      setMarkerCoords({});
      setTimeout(() => {
        setMapOpen(true);
      }, 100);
    } else {
      setMapOpen(false);
    }
  }, [open]);

  return (
    <Modal open={open} centered onCancel={onCancel} footer={null}>
      <Card size="small" style={{ width: "fit-content" }}>
        <Card.Meta
          avatar={<Avatar src={`${BASE_URL}/file/${nearby?.image}`} />}
          title={nearby?.name}
          // description="This is the description"
        />

      </Card>
      <br />
      <Form
        form={form}
        layout="vertical"
        onFinish={(e) => {
          onSuccess({
            name: e.name,
            geoLocation: markerCoords,
            nearby,
          });
        }}
      >
        <Form.Item
          label="name"
          name={"name"}
          required
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          {mapOpen ? (
            <MapBox
              markerCoords={markerCoords}
              setMarkerCoords={setMarkerCoords}
            />
          ) : (
            <></>
          )}
        </Form.Item>
        <Form.Item>
          <Button disabled={!markerCoords} type="primary" htmlType="submit">
            <LangText id={edit ? "edit" : "add"} />
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NearbyLocationForm;
