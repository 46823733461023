import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import { useGetMap } from "../../zustand/store";
import { Table } from "antd";
import { Link } from "react-router-dom";

function ChargingPointPage() {
  const map = useGetMap();
  useEffect(() => {
    map.execute({
      params: {
        // location: { ...markerCoords },
        location: { lat: 41.32049378591299, lng: 69.23571757817243 },
        bounds: undefined,
        radius: 50,
        // limit: 50,
      },
      force: true,
    });
  }, []);

  const data = map.data;
  const page = Number(data?.page) || 0;
  const limit = Number(data?.limit) || 0;
  const count = Number(data?.count) || 0;
  return (
    <PageContainer title={"Charging Point"}>
      <Table
        pagination={{
          current: page,
          onChange: (e) => {
            console.log(e);
            map?.execute({
              force: true,
              params: { page: e, limit },
            });
          },
          pageSize: limit,
          total: count,
        }}
        columns={["id", "address"].map((c) => ({
          key: c,
          dataIndex: c,
          title: c,
        }))}
        dataSource={
          map.data?.list?.map((data) => ({
            ...data,
            address: <Link to={`/cp/${data?.id}`}>{data?.address}</Link>,
          })) || []
        }
      />
      ChargingPointPage
    </PageContainer>
  );
}

export default ChargingPointPage;
