import styled from "styled-components";

export const PlugCard = styled.div`
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  padding: 7px;
  background-color: ${({ active }) => (active ? "lightgreen" : "transparent")};
  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin: auto;
    display: block;
  }
  div {
    text-align: center;
  }
`;
