import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import {
  useCouponValidate,
  useCouponsList,
  useMyCouponsList,
} from "../../zustand/store";
import { Button, Table } from "antd";

function CouponsPage() {
  const coupons = useCouponsList();
  const validate = useCouponValidate();

  useEffect(() => {
    coupons.execute();
  }, []);
  return (
    <PageContainer title={"Coupons"}>
      <Table
        columns={["promoCode", "action"].map((c) => ({
          key: c,
          dataIndex: c,
          title: c,
        }))}
        dataSource={coupons.data?.list?.map(({ promoCode }) => ({
          promoCode,
          action: (
            <div>
              <Button
                onClick={() => {
                  validate.execute({ data: {promoCode} });
                }}
              >
                Validate
              </Button>
            </div>
          ),
        }))}
      />
      CouponsPage
    </PageContainer>
  );
}

export default CouponsPage;
